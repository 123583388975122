import { MetricTypeKey } from './metric-type-keys';
import { getDefinitionByName } from '@legacy-modules/common/util/kpiDefinitions';
import MetricType from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';
import { ChartType } from '@legacy-modules/metrics2/models/enumerations/ChartType';

const label = 'TAN Sendungen im Rücklauf';

const metric = new MetricType({
  key: MetricTypeKey.SendungenTanRuecklauf,
  label,
  aggregation: AggregationType.sum,
  chart: ChartType.bar,
  positiveDirection: PositiveDirection.custom,
  definition: getDefinitionByName(label),
});

const expression = new SingleValueExpression(metric);
expression.apiVersion = 'ws';

export default expression;
