import { useCallback, useEffect, useState } from 'react';
import { filter, union } from 'lodash';

import { DeliveryItemTrackingData } from '../models/types/DeliveryItemTrackingData';
import { ServiceBadge } from '../components/ServicesFilterPanel';
import FinishedDelivery from '../models/entities/FinishedDelivery';

type Model = {
  services: string[];
  itemInfo: string[];
  bnk: string[];
  special: string[];
};

const model: Model = {
  services: ['eilservice', 'nachnahme', 'identservice', 'verbotNachbarschaftsabgabe'],
  itemInfo: ['colli', 'valuable', 'luggage', 'bulky', 'tan'],
  bnk: ['number', 'email'],
  special: ['wunschTag', 'wunschzeitFenster'],
};

type ServiceKey =
  | 'eilservice'
  | 'nachnahme'
  | 'identservice'
  | 'verbotNachbarschaftsabgabe'
  | 'colli'
  | 'valuable'
  | 'luggage'
  | 'bulky'
  | 'number'
  | 'email'
  | 'wunschTag'
  | 'wunschzeitFenster'
  | 'tan';

const EMAIL_BNK_NUMBER = '000000000000';

function serviceFnIfEmail(ifEmail: boolean) {
  return (list) =>
    filter(list, (fd) =>
      Object.keys(fd.customerDeliveries)
        .map((k) => fd.customerDeliveries[k])
        .find((cd) => cd.notificationInfo && (cd.notificationInfo === '000000000000') === ifEmail)
    );
}

const isWunschzeitfenster = (deliveryItem: DeliveryItemTrackingData) => {
  return deliveryItem.service?.wunschzeitFenster?.from != null;
};

type CountObject = {
  shipments: number;
  stops: number;
};

type GetCountsReturnType = Record<ServiceKey, CountObject>;

const initialStateObject = (): CountObject => ({
  shipments: 0,
  stops: 0,
});

export const servicesWithBadges = (() => {
  const servicesList = {};

  Object.entries(model).forEach(([category, vals]) => {
    if (!Array.isArray(vals)) {
      return;
    }

    vals.forEach((v) => {
      servicesList[v] = {
        value: v,
        selected: false,
        selectedCount: 1,
      };
    });
  });
  return servicesList;
})();

const knownServices = Object.keys(servicesWithBadges);

export const useServiceCounts = (deliveries: FinishedDelivery[]) => {
  const values = knownServices;

  const initialState = () =>
    Object.keys(servicesWithBadges).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: initialStateObject(),
      }),
      {}
    );

  const [counts, setCounts] = useState(initialState);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const promise = new Promise(function (resolve) {
      setTimeout(function () {
        resolve(getCounts(knownServices as ServiceKey[], deliveries));
      }, 0);
    });

    promise
      .then((result) => {
        if (!mounted) return;
        setCounts(result);
      })
      .finally(() => {
        if (!mounted) return;
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [deliveries, values]);

  return { loading, counts };
};

export const getCounts = (serviceKeys: ServiceKey[], deliveries: FinishedDelivery[]): GetCountsReturnType => {
  const servicesAndItemInfo = serviceKeys
    .filter((service) => model.services.includes(service) || model.itemInfo.includes(service))
    .reduce((acc, service) => {
      const key = model.services.includes(service) ? 'service' : 'itemInfo';
      const serviceStops = deliveries
        .filter(
          (stop) =>
            stop.deliveryItems.some((deliveryItem) => deliveryItem[key][service]) ||
            stop.returnDeliveryItems.some((deliveryItem) => deliveryItem[key][service])
        )
        .map((stop) => ({
          ...stop,
          deliveryItems: stop.deliveryItems.filter((deliveryItem) => deliveryItem[key][service]),
          returnDeliveryItems: stop.returnDeliveryItems.filter((deliveryItem) => deliveryItem[key][service]),
        }));
      return {
        ...acc,
        [service]: {
          stops: serviceStops.length,
          shipments: serviceStops
            .map((stop) => stop.deliveryItems.length + stop.returnDeliveryItems.length)
            .reduce((a, b) => a + b, 0),
        },
      } as GetCountsReturnType;
    }, {} as GetCountsReturnType);
  const wunschzeitFenster = serviceKeys
    .filter((service) => service === 'wunschzeitFenster')
    .reduce((acc, service) => {
      const serviceStops = deliveries
        .filter((stop) => stop.deliveryItems.some(isWunschzeitfenster))
        .map((stop) => ({
          ...stop,
          deliveryItems: stop.deliveryItems.filter(isWunschzeitfenster),
          returnDeliveryItems: stop.returnDeliveryItems.filter(isWunschzeitfenster),
        }));
      return {
        ...acc,
        [service]: {
          stops: serviceStops.length,
          shipments: serviceStops
            .map((stop) => stop.deliveryItems.length + stop.returnDeliveryItems.length)
            .reduce((a, b) => a + b, 0),
        },
      } as GetCountsReturnType;
    }, {} as GetCountsReturnType);
  const bnk = serviceKeys
    .filter((service) => model.bnk.includes(service))
    .reduce((acc, service) => {
      const deliveryFilter = (notificationInfo: string) =>
        service === 'email'
          ? notificationInfo === EMAIL_BNK_NUMBER
          : !!notificationInfo && notificationInfo !== EMAIL_BNK_NUMBER;
      const serviceStops = deliveries.filter((stop) =>
        Object.values(stop.customerDeliveries).some((delivery) => deliveryFilter(delivery.notificationInfo))
      );
      return {
        ...acc,
        [service]: {
          stops: serviceStops.length,
          shipments: serviceStops
            .map((stop) => stop.deliveryItems.length + stop.returnDeliveryItems.length)
            .reduce((a, b) => a + b, 0),
        },
      } as GetCountsReturnType;
    }, {} as GetCountsReturnType);
  return {
    ...servicesAndItemInfo,
    ...wunschzeitFenster,
    ...bnk,
    wunschTag: {
      stops: 0,
      shipments: 0,
    },
  };
};

const buildBadgeForService = (deliveryList: FinishedDelivery[], category, value) => {
  const identityFn = (di) => di;

  const badge: ServiceBadge = {
    value: value,
    serviceFn: identityFn,
    serviceFnRet: identityFn,
    fullServiceFn: (list: FinishedDelivery[]) => union(badge.serviceFn(list), badge.serviceFnRet(list)),
  };

  function fnsfromHelper(badge, helper) {
    badge.serviceFn = (list) => filter(list, (fd) => helper(fd.deliveryItems));
    badge.serviceFnRet = (list) => filter(list, (fd) => helper(fd.returnDeliveryItems));
  }

  if (category === 'services') {
    const helper = (di) => di.findIndex((deliveryItem) => deliveryItem.service[value]) !== -1;
    fnsfromHelper(badge, helper);
  }
  if (category === 'itemInfo') {
    const helper = (di) => di.findIndex((deliveryItem) => deliveryItem.itemInfo[value]) !== -1;
    fnsfromHelper(badge, helper);
  }
  if (category === 'special') {
    const helper = (di) => {
      if (value !== 'wunschzeitFenster') return false;
      return di.find(isWunschzeitfenster);
    };
    fnsfromHelper(badge, helper);
  }
  if (category === 'bnk') {
    badge.serviceFn = serviceFnIfEmail(value === 'email');
    badge.serviceFnRet = () => [];
  }
  return badge;
};

type ReturnType = {
  [service: string]: ServiceBadge;
};

export const useListOfServicesForStop = ({ deliveryList }) => {
  const buildListOfServicesForStop = useCallback((): ReturnType => {
    const servicesList = {};

    Object.entries(model).forEach(([category, vals]) => {
      if (!Array.isArray(vals)) {
        return;
      }

      vals.forEach((v) => {
        const badge = buildBadgeForService(deliveryList, category, v);
        servicesList[v] = badge;
      });
    });
    return servicesList;
  }, [deliveryList]);

  return {
    buildListOfServicesForStop,
  };
};
