import moment from 'moment';

import ShipmentSearchEntry from '@legacy-modules/shipments/models/entities/ShipmentSearchEntry';
import FinishedDelivery from '@legacy-modules/tour/models/entities/FinishedDelivery';

export const cleanBarcode = (barcode: string) => {
  if (!barcode) {
    return null;
  }
  return barcode.trim().toUpperCase();
};

export const isValidCode = (code: string): boolean => {
  if (!code.length) {
    return false;
  }

  const isValid12LengthCode = () => !!code.toLowerCase().match(/^\d{12}$/);
  const isValid20LengthCode = () => !!code.toLowerCase().match(/^\d{20}$/);

  const isValidShortCode = () => {
    return !!code.toLowerCase().match(/^\d{14}$/);
  };

  const isValidLongCode = () => {
    return !!code.toLowerCase().match(/^h\d{19}$/);
  };

  return isValid12LengthCode() || isValid20LengthCode() || isValidShortCode() || isValidLongCode();
};

export const getOrgKeyFromShipmentRow = (row: ShipmentSearchEntry) => {
  if (row.orgUnit?.orgKey == null) {
    return null;
  }

  if (!row.visibility.orgUnitKey) {
    // The permission exists only for a contractor, we were return the contractor key
    // instead of the org key leading to the zsb
    return row.visibility.contractorKey || null;
  }

  // If the selected orgKey is a od:, we need to replace
  // it with oz:, because in this case, we want to jump
  // to the tours of the given org unit, not the unit itself.
  // The tours of the od: are listed as "Touren : xyz" in the
  // org menu.
  if (row.orgUnit.orgKey.startsWith('od:')) {
    return row.orgUnit.orgKey.replace(/^od:/, 'oz:');
  }
  return row.orgUnit.orgKey;
};

export const findInTourDetails = (
  finishedDeliveries: FinishedDelivery[],
  shipmentId: string,
  estimatedTime: moment.Moment | null = null
): FinishedDelivery | null => {
  if (finishedDeliveries == null) return null;
  const byId = (item) => item.deliveryItemId === shipmentId;

  const matchingStops = [...finishedDeliveries].filter((stop) => {
    return stop.deliveryItems.find(byId) || stop.returnDeliveryItems.find(byId);
  });

  if (!matchingStops.length) {
    return null;
  }

  if (!estimatedTime) return matchingStops[0];

  const closestMatchByTime = matchingStops.reduce((prev, curr) => {
    const diffCurr = moment.duration(curr.finishedAt.diff(estimatedTime));
    const diffPrev = moment.duration(prev.finishedAt.diff(estimatedTime));

    return Math.abs(diffCurr.asSeconds()) < Math.abs(diffPrev.asSeconds()) ? curr : prev;
  }, matchingStops[0]);

  return closestMatchByTime;
};
