import * as React from 'react';
import ReturnDeliveryItemBadge from './ReturnDeliveryItemBadge';
import FinishedDeliveryBadge from './FinishedDeliveryBadge';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { Collapse } from 'reactstrap';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import { DeliveryItemTrackingData } from '../models/types/DeliveryItemTrackingData';
import '../assets/stopDetail.scss';
import { AttributeChoiceLabels } from '../../shipments/models/enums/AttributeLabels';
import moment from 'moment-timezone';
import DeliveryItemShipmentSearch from './DeliveryItemShipmentSearch';
import DeliveryItemServiceIcon from './DeliveryItemServiceIcon';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '../../common/components/Tooltip';
import {
  Email,
  Bnk,
  Eil,
  Mehrcollig,
  WunschTag,
  WunschzeitFenster,
  IdentService,
  Luggage,
  Nachnahme,
  Valuable,
  VerbotNachbarschaftsAbgabe,
  Bulky,
  Tan,
} from '../utils/ServiceIcons';
import DeliveryBadge from './DeliveryBadge';
import {
  IRawAnomalyMehrcolligResponseDetails,
  IRawAnomalyResponse,
} from '../../dashboard/anomalies/interfaces/IRawAnomalyResponse';
import { Address } from '@other-components/stop-details/address';

type Props = {
  finishedDelivery: FinishedDelivery;
  deliveryItems: Array<DeliveryItemTrackingData>;
  item: DeliveryItemTrackingData;
  customer: CustomerTrackingData;
  anomaly?: IRawAnomalyResponse;
};

const convertNachnahme = (nachnahme: number) => {
  if (nachnahme) {
    const val = nachnahme / 100;
    return val.toFixed(2).toString().replace('.', ',');
  }
};

const EMAIL_BNK = '000000000000';

const DeliveryItem: React.FC<Props> = (props) => {
  const { finishedDelivery, item, customer, anomaly } = props;
  const { wunschzeitFenster, eilservice, identservice, nachnahme, verbotNachbarschaftsabgabe } = item.service;

  const [isOpen, setIsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const openTooltip = useCallback(() => setTooltipOpen(true), []);
  const closeTooltip = useCallback(() => setTooltipOpen(false), []);

  const toggle = useCallback(() => setIsOpen((v) => !v), []);

  const [ref, setRef] = useState(null);

  if (!customer) {
    return null;
  }

  const { colli, valuable, itemCategory, luggage, bulky, tan } = item.itemInfo;
  const isWunschzeitFenster = wunschzeitFenster && wunschzeitFenster.from && wunschzeitFenster.to;
  const isWunschtag = wunschzeitFenster && Object.keys(wunschzeitFenster).length === 1;

  const customerDelivery = finishedDelivery.customerDeliveries[customer.customerRef];

  const wunschzeitDate = (() => {
    if (isWunschtag) {
      return moment(wunschzeitFenster.date, 'YYYY-MM-DD').format('DD.MM.YYYY');
    }
    return '';
  })();

  const isBNK = customerDelivery.notificationInfo && customerDelivery.notificationInfo !== EMAIL_BNK;
  const hasTagesVm = typeof customerDelivery.bnkNumber === 'number';

  let notification: React.ReactNode = null;
  if (
    customerDelivery &&
    customerDelivery.notifyCustomer &&
    item.deliveryItemClass !== 'ReturnParcel' &&
    (finishedDelivery.deliveryMode !== 'person' ||
      (customerDelivery && customerDelivery.recipientPerson === 'nextDoor'))
  ) {
    notification =
      customerDelivery.notificationInfo === EMAIL_BNK
        ? customerDelivery.notificationInfo === EMAIL_BNK && (
            <DeliveryItemServiceIcon title={'Email'} icon={<Email />} />
          )
        : customerDelivery.notificationInfo !== EMAIL_BNK && (
            <DeliveryItemServiceIcon
              title={`BNK: ${customerDelivery.notificationInfo !== EMAIL_BNK ? customerDelivery.notificationInfo : ''}`}
              icon={<Bnk />}
            />
          );
  }

  // TODO: Check if multiple badge implementations are necessary.
  const badge = (() => {
    if (item.deliveryItemClass === 'ReturnParcel') {
      return <ReturnDeliveryItemBadge deliveryItem={item} />;
    }
    if (item.status) {
      return <DeliveryBadge status={item.status} finishedDelivery={finishedDelivery} customer={customer} />;
    }
    return <FinishedDeliveryBadge finishedDelivery={finishedDelivery} customer={customer} />;
  })();

  const detailToggle = (
    <div>
      {isOpen ? (
        <FaAngleDown onClick={toggle} style={{ fontSize: '16px', marginTop: '12px', marginLeft: '8px' }} />
      ) : (
        <FaAngleRight onClick={toggle} style={{ fontSize: '16px', marginTop: '12px', marginLeft: '8px' }} />
      )}
    </div>
  );

  const nachnahmeConverted = convertNachnahme(nachnahme);
  return (
    <div className='delivery-item-wrapper'>
      {detailToggle}
      <div
        className={classNames('item-container', {
          highlighted: !!anomaly,
          open: isOpen,
        })}>
        <div className={classNames('first-tr-package')} key={item.deliveryItemId}>
          <div className='tr-package-left'>
            <div className='td-delivery-icon' onClick={toggle}>
              {badge}
            </div>
            <div
              ref={setRef}
              onMouseEnter={openTooltip}
              onMouseLeave={closeTooltip}
              style={{ fontWeight: 500 }}
              onClick={toggle}>
              {itemCategory}
            </div>
            <Tooltip placement='bottom' anchorElement={ref} visible={tooltipOpen}>
              {itemCategory ? AttributeChoiceLabels['shipment.itemCategory'][itemCategory] : ''}
            </Tooltip>
            <div className='td-package-id' onClick={toggle}>
              {item.deliveryItemId}
            </div>
            <DeliveryItemShipmentSearch barcode={item.deliveryItemId} />
          </div>
          <div className='tr-package-right' onClick={toggle}>
            {eilservice && <DeliveryItemServiceIcon title={'Eilservice'} icon={<Eil />} />}
            {colli && (
              <DeliveryItemServiceIcon
                title={
                  anomaly && anomaly.anomalyType === 's.colli-teillieferung'
                    ? `Mehrcollig ${(anomaly?.details as IRawAnomalyMehrcolligResponseDetails)?.partlyDeliveredCount}/${
                        (anomaly?.details as IRawAnomalyMehrcolligResponseDetails)?.totalShipmentsCount
                      }`
                    : 'Mehrcollig'
                }
                icon={<Mehrcollig />}
              />
            )}
            {isWunschtag && (
              <DeliveryItemServiceIcon title={`Wunschtag ${isWunschtag && wunschzeitDate}`} icon={<WunschTag />} />
            )}
            {isWunschzeitFenster && (
              <DeliveryItemServiceIcon
                title={`Wunschzeitfenster: ${wunschzeitDate}
              \n${isWunschzeitFenster && moment(wunschzeitFenster.from, 'HH:mm:ss').format('HH:mm')}
               Uhr bis ${isWunschzeitFenster && moment(wunschzeitFenster.to, 'HH:mm:ss').format('HH:mm')} Uhr`}
                icon={<WunschzeitFenster />}
              />
            )}
            {identservice && <DeliveryItemServiceIcon title={'Identservice'} icon={<IdentService />} />}
            {luggage && <DeliveryItemServiceIcon title={'DB Gepäck'} icon={<Luggage />} />}
            {!!nachnahme && (
              <DeliveryItemServiceIcon title={`Nachnahme: ${nachnahmeConverted}€`} icon={<Nachnahme />} />
            )}
            {valuable && <DeliveryItemServiceIcon title={`Wertsendung`} icon={<Valuable />} />}
            {verbotNachbarschaftsabgabe && (
              <DeliveryItemServiceIcon title={'Nachbarschaftsabgabeverbot'} icon={<VerbotNachbarschaftsAbgabe />} />
            )}
            {bulky && <DeliveryItemServiceIcon title='Bulky' icon={<Bulky />} />}
            {tan && <DeliveryItemServiceIcon title='TAN' icon={<Tan />} />}
            <div className='icon-md-import-parent'>
              {item.deliveryItemClass !== 'ReturnParcel' && <>{notification || ''}</>}
            </div>
          </div>
        </div>
        <div key={`${item.deliveryItemId}-tr`} style={{ width: '100%' }}>
          <Collapse isOpen={isOpen}>
            <div className='collapse-content-container'>
              <div className='sender-receiver-box'>
                <div className='customer-address-box'>
                  {item.returnParcelDetails &&
                  (item.returnParcelDetails.returnType === 'ReturnToSender' ||
                    item.returnParcelDetails.clientType === 'PriPS') ? (
                    <React.Fragment />
                  ) : (
                    <React.Fragment>
                      <div className='address-box-title'>Empfänger</div>
                      <Address {...customer} />
                    </React.Fragment>
                  )}
                </div>
                <div className='flex-column sender-address-box' style={{ width: '130px', paddingLeft: '5px' }}>
                  <div className='sender-box-title'>Auftraggeber</div>
                  <div className='sender-box-client'>{item.itemInfo.client}</div>
                </div>
              </div>
              <div className='additional-info-box'>
                {anomaly && anomaly.anomalyType === 's.colli-teillieferung' && (
                  <div className='label-info'>
                    {`Mehrcollig ${(anomaly?.details as IRawAnomalyMehrcolligResponseDetails)?.partlyDeliveredCount}/${
                      (anomaly?.details as IRawAnomalyMehrcolligResponseDetails)?.totalShipmentsCount
                    }`}
                  </div>
                )}
                {!!nachnahme && <div className='label-info'>{`Nachnahme: ${convertNachnahme(nachnahme)} €`}</div>}
                {isWunschtag && <div className='label-info'>{`Wunschtag: ${isWunschtag && wunschzeitDate}`}</div>}
                {isWunschzeitFenster && (
                  <div className='label-info'>
                    {`Wunschzeit: ${moment(wunschzeitFenster && wunschzeitFenster.date, 'YYYY-MM-DD').format(
                      'DD.MM.YYYY'
                    )}
                    \n${isWunschzeitFenster && moment(wunschzeitFenster.from, 'HH:mm:ss').format('HH')}
                    -${isWunschzeitFenster && moment(wunschzeitFenster.to, 'HH:mm:ss').format('HH')}
                     Uhr`}
                  </div>
                )}
                {isBNK && <div className='label-info'>{`BNK: ${customerDelivery.notificationInfo}`}</div>}
                {hasTagesVm && <div className='label-info'>{`Tages-VM: ${customerDelivery.bnkNumber}`}</div>}
              </div>
            </div>
            <div />
            <div />
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default DeliveryItem;
