import { v4 as uuidV4 } from 'uuid';
import ValueExpression from '../../valueexpressions/models/valueexpressions/ValueExpression';
import { MetricTypeKey } from '@contexts/value-expression-context/value-expressions/metric-type-keys';
import { sortBy } from 'lodash';
import AuthService from '../../common/services/AuthService';
import {
  abhMitReturnTypes,
  deliveryTypesShipmentBased,
  raaReasons,
  shipmentTypes,
  undeliverableReasonsShipmentBased,
} from '../../metrics2/models/entities/MetricType';
import { NodeData, TreeMenuNode } from '../../navigation/TreeMenu';
import { ContractorUtils } from '@legacy-modules/utils/tours/ContractorUtils';
import { ValueExpressionMap } from '../hooks/useChartDataTransformation';

export const getValueExpressionNodeByKey = (key: string, ves: ValueExpressionMap): ValueExpressionNode | null => {
  if (!ves) return null;
  return ves.has(key) ? new ValueExpressionNode(ves.get(key)) : null;
};

const rlByUndeliverableReason = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Rückläufer nach Grund',
    undeliverableReasonsShipmentBased.map((r) => getValueExpressionNodeByKey(`ruecklauefer_gruende.${r}`, ves)),
    MetricTypeKey.RLbyUndeliverableReason
  );

const rlExpressByUndeliverableReason = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Rückläufer Eil nach Grund',
    undeliverableReasonsShipmentBased.map((r) => getValueExpressionNodeByKey(`ruecklauefer_eil_gruende.${r}`, ves)),
    MetricTypeKey.RLExpressByUndeliverableReason
  );

const zuByDeliveryType = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Zustellungen nach Art',
    deliveryTypesShipmentBased.map((r) => getValueExpressionNodeByKey(`zustellungen_arten.${r}`, ves)),
    MetricTypeKey.ZUbyDeliveryType
  );

const aBHbyReturnType = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Abholungen nach Typ',
    abhMitReturnTypes.map((r) => getValueExpressionNodeByKey(`${MetricTypeKey.ABHbyReturnType}.${r}`, ves)),
    MetricTypeKey.ABHbyReturnType
  );

const mITbyReturnType = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Mitnahmen nach Typ',
    abhMitReturnTypes.map((r) => getValueExpressionNodeByKey(`${MetricTypeKey.MITbyReturnType}.${r}`, ves)),
    MetricTypeKey.MITbyReturnType
  );

const rAAbyReturnType = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Rücklauf Abholkarten nach Grund',
    raaReasons.map((r) => getValueExpressionNodeByKey(`aba_ruecklauefer_gruende.${r}`, ves)),
    MetricTypeKey.RAAbyUndeliverableReason
  );

const tAByShipmentType = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Lademenge nach Sendungsart',
    shipmentTypes.map((r) => getValueExpressionNodeByKey(`lademenge_sendungsarten.${r}`, ves)),
    MetricTypeKey.TARelevantByShipmentType
  );

const sendungsbasiert = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain('Sendungsbasiert', [
    ...[
      MetricTypeKey.MITsum,
      MetricTypeKey.TAAsum,
      MetricTypeKey.ZAAsum,
      MetricTypeKey.RLExpressSum,
      MetricTypeKey.TAValuableSum,
    ].map((k) => getValueExpressionNodeByKey(k, ves)),
    aBHbyReturnType(ves),
    mITbyReturnType(ves),
    tAByShipmentType(ves),
  ]);

const development = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    '## Development ##',
    [
      MetricTypeKey.AnomaliesWzfHTZustellung,
      MetricTypeKey.AnomaliesWzfHTAbholung,
      MetricTypeKey.AnomaliesWzfAtgAbholung,
      MetricTypeKey.AnomaliesWzfUnklassifiziert,
    ].map((k) => getValueExpressionNodeByKey(k, ves))
  );

const anomaliesWithPrototypes = (ves: Map<string, ValueExpression>) =>
  KpiMenuNode.createFromPlain(
    'Hinweise',
    [
      'anomalies/wzf-ht',
      MetricTypeKey.AnomaliesWzfAtgAbholung,
      MetricTypeKey.AnomaliesZustelltag,
      MetricTypeKey.AnomaliesWunschtag,
      MetricTypeKey.AnomaliesZustellzeit,
      MetricTypeKey.AnomaliesTeillieferung,
      'ruecklauf_am_tourende',
    ].map((k) => getValueExpressionNodeByKey(k, ves))
  );

export class ValueExpressionNode implements TreeMenuNode<NodeData> {
  constructor(public readonly valueExpression: ValueExpression) {}

  public parent: KpiNode;

  get data() {
    return {
      toString: () => this.valueExpression.getLabel(),
      getLongLabel: () => this.valueExpression.longLabel,
      getIdentifier: () => this.valueExpression.identifier,
    };
  }

  matchesSearchInput(inputValue: string): boolean {
    // Billing kpis contain digits that staff searches for
    const isNumberInput = /^\d+$/.test(inputValue);
    if (isNumberInput) {
      return this.data.getIdentifier().toLocaleLowerCase().includes(inputValue);
    }

    return (
      this.data.toString().toLocaleLowerCase().includes(inputValue) ||
      this.data.getLongLabel()?.toLocaleLowerCase()?.includes(inputValue)
    );
  }
}

export class KpiMenuNode {
  public static createFromPlain(
    label: string,
    children: Array<KpiNode | ValueExpressionNode>,
    valueExpressionKey?: string,
    type: string = 'category',
    uid: string = uuidV4()
  ) {
    const node = new KpiNode(label, children, type, uid);
    node.valueExpressionKey = valueExpressionKey;
    children.forEach((c) => {
      if (!c) return;
      c.parent = node;
    });
    return node;
  }
}

export class KpiNode implements TreeMenuNode<NodeData> {
  public parent: KpiNode | null;
  public valueExpressionKey: string;

  get data(): NodeData {
    return {
      toString: () => this.label,
      getIdentifier: () => this.uid,
    };
  }

  matchesSearchInput(inputValue: string): boolean {
    return this.data.toString().toLocaleLowerCase().includes(inputValue);
  }

  constructor(
    public readonly label: string,
    public children: Array<KpiNode | ValueExpressionNode>,
    private readonly type: string = 'category',
    private readonly uid: string = uuidV4()
  ) {}
}

const buildKpiTree = (authService: AuthService, ves: Map<string, ValueExpression>): KpiNode[] => {
  const tree = [
    KpiMenuNode.createFromPlain('Schäden & Verluste', [
      getValueExpressionNodeByKey('regression-gutschriften/abwicklungsmenge', ves),
    ]),
    KpiMenuNode.createFromPlain(
      'Touren',
      [
        MetricTypeKey.TourLoadedCount,
        'touren',
        MetricTypeKey.TARelevantSum,
        MetricTypeKey.TAsum,
        'tourfreigabe_zeitpunkt/touren',
      ].map((k) => getValueExpressionNodeByKey(k, ves))
    ),
    KpiMenuNode.createFromPlain(
      'Tourlänge & -Dauer',
      [
        ...[
          'bruttozustellzeit/touren',
          MetricTypeKey.BruttoZustellzeitGesamt,
          'nettozustellzeit/touren',
          'nettozustellzeit',
          'netto-ht-zeit',
          'netto-ht-zeit/touren',
          'netto-ps-zeit',
          'netto-ps-zeit/touren',
          'netto-atg-zeit',
          'netto-atg-zeit/touren',
          'netto-zustellzeit',
          'netto-zustellzeit/touren',
          'anfahrtszeit/touren',
          'anfahrtszeit',
          'rueckfahrtzeit/touren',
          'rueckfahrtzeit',
          'tourfahrtzeit',
          'tourfahrtzeit/touren',
          'tourstrecke/touren',
          'tourstrecke',
          'rueckfahrtstrecke',
          'rueckfahrtstrecke/touren',
          'anfahrtsstrecke',
          'anfahrtsstrecke/touren',
          MetricTypeKey.TourStoppsHt,
          MetricTypeKey.TourStoppsPs,
          MetricTypeKey.TourStoppsAtg,
          'anfahrtsstrecke/touren_v1',
          `${MetricTypeKey.ReihenfolgeeinhaltungQuote}_quotient`,
        ],
      ].map((k) => getValueExpressionNodeByKey(k, ves))
    ),
    KpiMenuNode.createFromPlain('Zustellvorgänge', [
      zuByDeliveryType(ves),
      ...[
        'finished_deliveries.notifications.count',
        'zustellungen/touren',
        'zustellungen',
        'zustellungen_nachbar_quote',
        'imageSignature_quote',
        'dbn_quote',
        'dvm_quote',
      ].map((k) => getValueExpressionNodeByKey(k, ves)),
      KpiMenuNode.createFromPlain(
        'Benachrichtigungen nach Art',
        ['finished_deliveries.notifications.card', 'finished_deliveries.notifications.email'].map((k) =>
          getValueExpressionNodeByKey(k, ves)
        ),
        MetricTypeKey.FinishedDeliveriesNotificationsByType
      ),
    ]),
    KpiMenuNode.createFromPlain('Abholvorgänge', [
      ...['abholungenProPS/touren', 'abholungen/touren', 'abholungen', MetricTypeKey.ABHMITsum].map((k) =>
        getValueExpressionNodeByKey(k, ves)
      ),
      rAAbyReturnType(ves),
      ...['abholauftraege', 'erfolgreiche_abholauftraege', 'atg_abholungen'].map((k) =>
        getValueExpressionNodeByKey(k, ves)
      ),
    ]),
    KpiMenuNode.createFromPlain(
      'Paketshop',
      ['psabgaben/touren', 'psmitnahmen/touren', MetricTypeKey.PSEsum, 'psabgaben'].map((k) =>
        getValueExpressionNodeByKey(k, ves)
      )
    ),
    KpiMenuNode.createFromPlain('Laufzeiten und Bestände', [
      ...[
        MetricTypeKey.StockRemaining,
        MetricTypeKey.StockRemainingExpress,
        MetricTypeKey.StockStored,
        MetricTypeKey.StockAFNUS,
        MetricTypeKey.StockInventory,
        MetricTypeKey.StockIncoming,
        'stock.remaining_per_inventory',
        'stock.remaining_express_per_inventory_express',
        'stock.stored_per_inventory',
        'same_day.e_plus_0',
        'same_day.e_plus_0_express',
      ].map((k) => getValueExpressionNodeByKey(k, ves)),
      ...['zsb_plus_0_standard', 'zsb_plus_0_express'].map((k) => getValueExpressionNodeByKey(k, ves)),
    ]),
    KpiMenuNode.createFromPlain('Produkte & Services', [
      getValueExpressionNodeByKey('wertsendungen', ves),
      getValueExpressionNodeByKey(MetricTypeKey.TABulkyTotalCount, ves),
      getValueExpressionNodeByKey(MetricTypeKey.ZABulkyZugestellt, ves),
      getValueExpressionNodeByKey(MetricTypeKey.SendungenTanTour, ves),
      getValueExpressionNodeByKey(MetricTypeKey.SendungenTanZugestellt, ves),
      getValueExpressionNodeByKey(MetricTypeKey.SendungenTanRuecklauf, ves),
    ]),
    KpiMenuNode.createFromPlain(
      'Leistung',
      [
        'produktivitaet',
        'ht-produktivitaet',
        'ps-produktivitaet',
        'atg-produktivitaet',
        'ist_lademenge/touren',
        'soll_lademenge/touren',
        MetricTypeKey.AbwicklungsmengeZsb,
        MetricTypeKey.AbwicklungsmengeAll,
      ].map((k) => getValueExpressionNodeByKey(k, ves))
    ),
    KpiMenuNode.createFromPlain('Servicebeanstandungen', [
      ...[
        'complaints.quote.count/complaints.quote.base',
        'complaints.quote.count_lossrelevant/complaints.quote.base',
        'sb_all',
        'sb_verlust',
      ].map((k) => getValueExpressionNodeByKey(k, ves)),
      // TODO: Re-enable with full sb release
      // ves.get('sb_tour'),
      // ves.get('sb/tour'),
      KpiMenuNode.createFromPlain(
        'SB nach Grund (Meldung)',
        [
          'complaints.reported.BNK - Keine',
          'complaints.reported.Verspätung',
          'complaints.reported.F-Adresse ist richtig',
          'complaints.reported.unklarer Sendungsverbleib',
          'complaints.reported.unklarer Abliefernachweis',
          'complaints.reported.Fehlerhafte BNK',
          'complaints.reported.Umverfügung nicht eingehalten',
          'complaints.reported.fehlerhafte Scannung',
          'complaints.reported.fehlerhafte A-Scannung',
          'complaints.reported.fehlerhafte N-Scannung',
          'complaints.reported.Fehler bei der Abholung',
          'complaints.reported.Fehler bei der Zustellung',
          'complaints.reported.Unerwünschte Zustellung am PaketShop (PS)',
          'complaints.reported.Kontaktlose Zustellung nicht erfolgt',
          'complaints.reported.Abholung nicht erfolgt',
          'complaints.reported.keine Quittung',
          'complaints.reported.Paketankündigung nicht eingehalten',
          'complaints.reported.Schaden gemeldet',
          'complaints.reported.Inhalt fehlt (Teilverlust)',
          'complaints.reported.Haftpflichtschaden',
          'complaints.reported.Korrekturbeleg eingetroffen',
          'complaints.reported.Widerspruch',
          'complaints.reported.Rückzahlung von KD/ATG',
          'complaints.reported.Zahlungsrückläufer',
          'complaints.reported.Unfreundlicher Mitarbeiter',
          'complaints.reported.Prävention',
          'complaints.reported.Ungepflegtes Erscheinungsbild',
          'complaints.reported.Sendung verschmutzt/riecht nach Rauch',
          'complaints.reported.Fehlerhafte Datenänderung',
          'complaints.reported.sprachliche Probleme / Verständigungsprobleme',
          'complaints.reported.unzureichende / falsche Beratung',
          'complaints.reported.Ermittlung durch Polizei/Staatsanwaltschaft',
          'complaints.reported.Bearbeitungszeit überschritten',
          'complaints.reported.Reklamation unvollständig abgeschlossen',
          'complaints.reported.PaketShop existiert nicht mehr/ falsche Adresse',
          'complaints.reported.Öffnungszeiten des Paketshops wurden nicht eingehalten',
          'complaints.reported.Hermes App - allgemeine Probleme',
          'complaints.reported.Hermes App - Verbindungsabbrüche',
          'complaints.reported.Hermes App - Sendungsverfolgung fehlerhaft',
        ].map((k) => getValueExpressionNodeByKey(k, ves)),
        'complaints.reported.count_by_type'
      ),
    ]),
    KpiMenuNode.createFromPlain('Rückläufer', [
      ...['ruecklaufquote', 'ruecklaeufer/touren', 'ruecklaeufer', 'ruecklauefer_eil'].map((k) =>
        getValueExpressionNodeByKey(k, ves)
      ),
      rlByUndeliverableReason(ves),
      rlExpressByUndeliverableReason(ves),
    ]),
  ];

  tree.push(
    KpiMenuNode.createFromPlain(
      'Abrechnung',
      [
        MetricTypeKey.BillingTourDateStandard,
        MetricTypeKey.BillingTourDateBulky,
        MetricTypeKey.BillingTourDateIdentservice,
        MetricTypeKey.BillingTourDateNachnahme,
        MetricTypeKey.BillingTourDateGepaeckabholung,
        MetricTypeKey.BillingTourDateZeitfenster,
        MetricTypeKey.BillingTourDateZusMitnahme,
        MetricTypeKey.BillingTourDateQuittungslos,
        MetricTypeKey.BillingTourDateCoicident,
        MetricTypeKey.BillingTourDatePSStopps,
        MetricTypeKey.BillingTourDatePSSendungen,
        MetricTypeKey.BillingTourDateATGStopps,
        MetricTypeKey.BillingTourDateATGSendungen,
        MetricTypeKey.BillingTourDateEilSendungen,
        MetricTypeKey.Altgeraetemitnahme,
      ].map((k) => getValueExpressionNodeByKey(k, ves))
    )
  );

  return tree;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortTree = <T extends { data: { toString: () => string }; children: any[] }>(tree: T[]): T[] => {
  const sorted = sortBy(tree, (t) => {
    if (!t) return null;
    return t.data.toString();
  });
  sorted.forEach((t) => {
    if (!t?.children) return t;
    t.children = sortTree(t.children);
  });
  return sorted;
};

export const getKpiTree = (
  authService: AuthService,
  ves: Map<string, ValueExpression>,
  orgKey: string
): TreeMenuNode<NodeData>[] => {
  let tree = buildKpiTree(authService, ves);

  if (ContractorUtils.isContractor(orgKey)) {
    const laufzeitenUndBestaendeNode = tree.find((t) => t.label === 'Laufzeiten und Bestände');
    laufzeitenUndBestaendeNode.children = laufzeitenUndBestaendeNode.children.filter((child) =>
      ['same_day.e_plus_0', 'same_day.e_plus_0_express'].includes(child.data.getIdentifier())
    );
    tree = tree.filter((t) => t.label !== 'Servicebeanstandungen');
  }

  if (authService.can('shipment-based-kpis')) {
    tree.push(sendungsbasiert(ves));
  }
  if (authService.can('kpi.show-development')) {
    tree.push(development(ves));
  }
  tree.push(anomaliesWithPrototypes(ves));

  return sortTree(tree);
};
