import Color from 'color';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdCheck, MdPerson, MdPinDrop, MdRefresh } from 'react-icons/md';
import { Map as MapboxReactMap, Popup } from 'react-mapbox-gl';
import GeoCoordinateBounds from '../../geo/models/GeoCoordinateBounds';
import StopMarker from '../../markers/components/StopMarker';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import MarkerDetails from '../models/entities/MarkerDetails';
import DeliveryModes from '../models/enumerations/DeliveryMode';
import { MarkerType } from '../models/enumerations/MarkerType';
import CustomerTrackingData from '../models/types/CustomerTrackingData';
import { DeliveryItemTrackingData } from '../models/types/DeliveryItemTrackingData';
import MapUtils, { Bounds } from '../../utils/map/MapUtils';
import arrowUp from '../assets/svg/abholung_sendung_icon.svg';
import arrowDown from '../assets/svg/zustellung_sendung_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { FaAngleRight } from 'react-icons/fa';
import { MarkerColor } from '../models/enumerations/MarkerColors';
import './../assets/stopDetail.scss';
import DeliveryItemsList from './DeliveryItemsList';
import { countBy, filter, groupBy } from 'lodash';
import ReturnDeliveryItemBadge from './ReturnDeliveryItemBadge';
import FinishedDeliveryBadge from './FinishedDeliveryBadge';
import Styles from './FinishedDeliveryStopDetail.module.scss';
import { SignatureImageContainer } from '../containers/SignatureImageContainer';
import AuthGuard from '../../auth/components/AuthGuard';
import { WellKnownPermission } from '../../auth/constants/WellKnownPermission';
import { buildCustomerMarker } from '../converters/MarkerSetGenerator';
import classNames from 'classnames';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { AnomalyReason } from '../../dashboard/anomalies/utils/AnomalyUtils';
import { knownAnomalyFilter } from '../utils/KnownAnomalyFilter';
import { ConfigKey, getEnvVariable, getMapboxStyles } from '../../common/services/EnvironmentConfigurationService';
import { MarkerOffset } from '../constants/MarkerOffset';
import { Address } from '@other-components/stop-details/address';
import { useAuthContext } from '@contexts/auth-context';
import { useTourReport } from '@hooks/use-tour-report-hook';
import TourDetailsGenerator from '../converters/TourDetailsGenerator';
import { tourDetailsSlice } from '@redux/tour-details.slice';
import { selectTourDetailsContractorKey, selectTourDetailsTourIdentifier } from '@redux/tour-details.selectors';

const StopHeader = ({ children, offset = 0 }) => {
  return (
    <div className={'stop-header'} style={{ marginBottom: offset }}>
      {children}
    </div>
  );
};

export const Warning = ({ children }) => {
  return (
    <div className={Styles.Warning}>
      <span className={Styles.IconWrapper}>
        <AiFillExclamationCircle />
      </span>
      {children}
    </div>
  );
};

type Props = {
  finishedDelivery: FinishedDelivery;
  customers: Array<CustomerTrackingData>;
  deliveryItems: Array<DeliveryItemTrackingData>;
  url: string | null | undefined;
  returnDeliveryItems: Array<DeliveryItemTrackingData>;
  shipmentIdToCustomerMap: Map<string, string>;
};
// eslint-disable-next-line new-cap
const MapboxMap = MapboxReactMap({
  accessToken: getEnvVariable(ConfigKey.MAPBOX_TOKEN),
  maxZoom: 17,
  minZoom: 5,
});

type StopListTooltipProps = {
  finishedDelivery: FinishedDelivery;
  deliveryReturnType: 'returnDeliveryItems' | 'deliveryItems';
  selectedCustomerLat: number;
  customers: CustomerTrackingData[];
};

const StopListToolTip: React.FC<StopListTooltipProps> = (props) => {
  const { finishedDelivery, deliveryReturnType, selectedCustomerLat, customers } = props;

  const sortedPackagesByLocation = useMemo(() => {
    const groupedByLocation = groupBy(customers, (customer) => customer?.location?.lat);
    if (!selectedCustomerLat) {
      return [];
    }

    return groupedByLocation[selectedCustomerLat]?.map((i) => {
      const items = finishedDelivery[deliveryReturnType];
      return filter(items, (item) => item.customerRef === i.customerRef);
    });
  }, [customers, deliveryReturnType, finishedDelivery, selectedCustomerLat]);

  if (!sortedPackagesByLocation) {
    return null;
  }

  return (
    <>
      {sortedPackagesByLocation.flatMap((items) => {
        return items.map((item, i) => {
          const cRef = item.customerRef;
          const filteredCustomer = filter(customers, (c) => c.customerRef === cRef)[0];
          const badge =
            item.deliveryItemClass === 'ReturnParcel' ? (
              <ReturnDeliveryItemBadge deliveryItem={item} />
            ) : (
              <FinishedDeliveryBadge finishedDelivery={finishedDelivery} customer={filteredCustomer} />
            );
          return (
            <div key={`${item.id}${i}`} className={`stop-list-single-row border-bot`}>
              <div className='first-bottom-border'>
                <div className='badge-modal-list'>{badge}</div>
                <div className='name-modal-list'>
                  {filteredCustomer.lastName && filteredCustomer.lastName.toLowerCase()}
                </div>
              </div>
            </div>
          );
        });
      })}
    </>
  );
};

const { satellite } = getMapboxStyles();

const FinishedDeliveryStopDetail: React.FC<Props> = (props: Props) => {
  const { customers, finishedDelivery, shipmentIdToCustomerMap, url } = props;
  const { date, number: tourNumber, orgId } = useSelector(selectTourDetailsTourIdentifier);
  const contractorKey = useSelector(selectTourDetailsContractorKey);

  const dispatch = useDispatch();

  const authService = useAuthContext();
  const [markerDetails, setMarkerDetails] = useState<Array<MarkerDetails>>([]);

  const [mapBounds, setMapBounds] = useState<Bounds>([
    [Number.MIN_VALUE, Number.MIN_VALUE],
    [Number.MAX_VALUE, Number.MAX_VALUE],
  ]);
  const [pinsHidden, setPinsHidden] = useState(false);
  const [markerClicked, setMarkerClicked] = useState(false);
  const [selectedCustomerLat, setSelectedCustomerLat] = useState<number | null>(null);

  const orgKey = useMemo(() => {
    return `oz_t:${orgId}_${tourNumber}`;
  }, [orgId, tourNumber]);

  const tourDetailsResponse = useTourReport(orgKey, date, date, contractorKey);
  const tourDetails = useMemo(() => {
    if (!tourDetailsResponse?.data?.metrics?.[0]?.value) {
      return null;
    }
    const generator = new TourDetailsGenerator();
    return generator.convert(JSON.parse(tourDetailsResponse.data.metrics?.[0].value));
  }, [tourDetailsResponse?.data]);

  useEffect(() => {
    if (!markerDetails || !customers?.length) {
      return;
    }

    const coordinateBounds: GeoCoordinateBounds = GeoCoordinateBounds.buildFromCoordinateList(
      markerDetails.map((m) => m.location)
    );
    const _mapBounds = coordinateBounds.getAsLngLatLikeArray();
    if (MapUtils.boundsChanged(mapBounds, _mapBounds)) {
      setMapBounds(_mapBounds);
    }
  }, [customers, mapBounds, markerDetails]);

  const customer = customers[0];
  const customersNumber = customers.length;
  const deliverCount = finishedDelivery.deliveryItems.length;
  const collectCount = finishedDelivery.returnDeliveryItems.length;
  const isNeighbor = Object.values(finishedDelivery.customerDeliveries).some((cd) => cd.recipientPerson === 'nextDoor');

  const isNotAllowedToSeeTourDetails = !authService.can('ui.tour.see-details');
  const isHermesDriver = tourDetails?.driver?.employmentType === 'internal';
  const isAllowedToSeeHermesTours = authService.can('ui.tour.employment-types.all');

  useEffect(() => {
    if (!customers?.length) {
      return;
    }
    const getCustomerMarkers = () => {
      if (!customers?.length) {
        return [];
      }
      // Below is to cover the case when there are multiple customers with different addresses on the same stop
      // It groups them by geolocation and then counts the number of items in nested loops
      const groupedByLocation = groupBy(customers, (customer) => customer.location?.lat);

      return Object.values(groupedByLocation).flatMap((customers) => {
        const itemCount = customers.reduce((sum, customer) => {
          const { deliveryItemCount, returnDeliveryItemCount } =
            finishedDelivery.customerDeliveries[customer.customerRef];
          return sum + deliveryItemCount + returnDeliveryItemCount;
        }, 0);

        return customers.filter((c) => c.location).map((c) => buildCustomerMarker(c, finishedDelivery, itemCount));
      });
    };

    const customerMarkers = getCustomerMarkers();
    if (customerMarkers.length) {
      setPinsHidden(false);
    }

    const actualMarkers = (() => {
      if (!finishedDelivery?.location?.isGpsLocation()) {
        return [];
      }
      const color = MarkerColor.byKindAndModeWithDefault('gps', finishedDelivery.deliveryMode);

      return [
        new MarkerDetails({
          markerRef: 'actualLocation',
          markerType: MarkerType.finishedDelivery,
          stopNumber: finishedDelivery.displayableStopNumber,
          color: new Color(color.color),
          location: finishedDelivery.location,
          isClustered: false,
          content:
            finishedDelivery.deliveryMode === DeliveryModes.undeliverable ? (
              <MdRefresh color='white' size={24} />
            ) : (
              <MdCheck color='white' size={24} />
            ),
          tooltipCallback: () => (
            <AuthGuard
              notAllowedComponent={<div>GPS Scanner</div>}
              requiredPermissions={[WellKnownPermission.TourShowGpsRawData]}>
              <div style={{ textAlign: 'center' }}>
                N {finishedDelivery.location.lat}&#176;, E {finishedDelivery.location.lng}&#176;
                <br />
                Genauigkeit: {finishedDelivery.location.accuracy}m
              </div>
            </AuthGuard>
          ),
        }),
      ];
    })();

    setMarkerDetails([...actualMarkers, ...customerMarkers]);
  }, [
    authService,
    customer.customerRef,
    customer.location,
    customer.street,
    customer.streetNr,
    customers,
    customers?.length,
    finishedDelivery,
    finishedDelivery.customerDeliveries,
    finishedDelivery.deliveryMode,
    finishedDelivery.location,
    finishedDelivery.displayableStopNumber,
  ]);

  const [selectedMarkerRef, setSelectedMarkerRef] = useState<string | null>();
  const [clickedMarkerRef, setClickedMarkerRef] = useState<string | null>();

  const _onMarkerEnter = useCallback((markerRef: string) => {
    setSelectedMarkerRef(markerRef);
  }, []);

  const _onMarkerClick = useCallback((markerRef: string) => {
    setClickedMarkerRef(markerRef);
  }, []);

  const _onMarkerLeave = useCallback(() => {
    setSelectedMarkerRef(null);
  }, []);

  const goBackToTour = useCallback(() => dispatch(tourDetailsSlice.actions.setSelectedStop(null)), [dispatch]);

  const onClickActualLocation = useCallback(
    (markerRef: string, coordinates) => {
      _onMarkerClick(markerRef);
      coordinates && setMapBounds([coordinates, coordinates]);
    },
    [_onMarkerClick]
  );

  const togglePins = useCallback(() => {
    setPinsHidden((v) => !v);
  }, []);

  useEffect(() => {
    setMarkerClicked(false);
  }, [finishedDelivery]);

  const addressComponent = (() => {
    if (!finishedDelivery.differingAddress && customer && !isNeighbor) {
      if (!customer.street && !customer.streetNr && !customer.lastName) {
        return <div style={{ paddingLeft: '10px' }}>Keine Adresse vorhanden</div>;
      }
      return (
        <div className='customer-address'>
          <Address {...customer} entitled={finishedDelivery.entitled} />
        </div>
      );
    }
    return (
      <div className='customer-address'>
        <Address
          paketShopId={customer.paketShopId}
          entitled={finishedDelivery.entitled}
          {...finishedDelivery.differingAddress}
        />
      </div>
    );
  })();

  const onStopMarkerClick = useCallback(
    (markerDetails: MarkerDetails, coordinates) => {
      setMarkerClicked((v) => !v);
      _onMarkerClick(markerDetails.markerRef);
      if (coordinates) {
        setMapBounds([coordinates, coordinates]);
        setSelectedCustomerLat(markerDetails.location.lat);
      }
    },
    [_onMarkerClick]
  );

  const anomalies = tourDetails?.anomalies;

  if (isNotAllowedToSeeTourDetails || (isHermesDriver && !isAllowedToSeeHermesTours)) {
    return null;
  }
  if (tourDetails == null) {
    return null;
  }

  if (customers.length === 0) {
    return null;
  }

  const map = (
    <div className={classNames(Styles.MapWrapper)}>
      <MapboxMap
        containerStyle={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
        style={satellite}
        fitBounds={mapBounds}
        fitBoundsOptions={{ padding: 60 }}>
        <>
          <div title='Pins umschalten' className={Styles.PinToggleContainer}>
            <MdPinDrop
              style={pinsHidden ? { color: '#CFCFCF' } : {}}
              onClick={togglePins}
              className={Styles.PinToggleIcon}
            />
          </div>
          {!pinsHidden &&
            markerDetails.map((markerDetails: MarkerDetails, i) => {
              const [first, second] = markerDetails.location.toReversedArray();
              const coordinates = [first, second];
              if (markerDetails.markerRef === 'actualLocation') {
                return (
                  <StopMarker
                    key={`${markerDetails.markerRef}_${i}`}
                    isActive
                    zIndex={markerDetails.markerRef === clickedMarkerRef ? 11 : 0}
                    isStopDetail
                    isClustered={false}
                    isHovered={true}
                    coordinates={coordinates}
                    markerDetails={markerDetails}
                    contents={markerDetails.content}
                    color={markerDetails.color}
                    size={'default'}
                    finishedDelivery={finishedDelivery}
                    onMouseEnter={() => _onMarkerEnter(markerDetails.markerRef)}
                    onMouseLeave={_onMarkerLeave}
                    onClick={() => onClickActualLocation(markerDetails.markerRef, coordinates)}
                  />
                );
              } else {
                return (
                  <StopMarker
                    key={`${markerDetails.markerRef}_${i}`}
                    isActive
                    isStopDetail
                    zIndex={markerDetails.markerRef === clickedMarkerRef ? 20 : 0}
                    isClustered={markerDetails.isClustered}
                    isHovered={true}
                    coordinates={coordinates}
                    markerDetails={markerDetails}
                    contents={markerDetails.content}
                    color={markerDetails.color}
                    size={markerDetails.isClustered ? 'big' : 'default'}
                    finishedDelivery={finishedDelivery}
                    onMouseEnter={() => _onMarkerEnter(markerDetails.markerRef)}
                    onMouseLeave={_onMarkerLeave}
                    onClick={() => onStopMarkerClick(markerDetails, coordinates)}
                  />
                );
              }
            })}
          {markerDetails
            .filter((marker: MarkerDetails) => marker.markerRef === selectedMarkerRef)
            .map((marker: MarkerDetails, i) => (
              <Popup
                style={{
                  zIndex: 100,
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
                // @ts-ignore
                offset={MarkerOffset.selected}
                key={`marker_tooltip_${marker.markerRef}_${i}`}
                coordinates={[marker.location.lng, marker.location.lat]}>
                {marker.tooltipCallback()}
              </Popup>
            ))}
        </>
      </MapboxMap>
    </div>
  );

  const getAnomaliesForStopIndex = (index: number) => {
    if (!tourDetails || !anomalies) return [];

    const filteredAnomalies = anomalies.filter((a) => a.tourStopRefs.flatMap((ref) => ref.stopNumber).includes(index));

    const knownAnomalies = filteredAnomalies.filter(knownAnomalyFilter);

    const groupedByType = countBy(knownAnomalies, 'anomalyType');

    return Object.entries(groupedByType).map(([name, count]) => ({
      name,
      count,
    }));
  };

  const anomaliesForStop = getAnomaliesForStopIndex(finishedDelivery.index);

  return (
    <div className='stop-detail'>
      <div className='flex flex-column '>
        <div className='flex flex-row container-breadcrumb-tour'>
          <span className='headline-back-tour' onClick={goBackToTour}>
            Tour {tourNumber}
          </span>
          <FaAngleRight
            style={{
              height: '16px',
              position: 'relative',
              top: '1px',
              color: '#333',
              fontSize: '20px',
            }}
          />
          <h2 className='headline'>Stopp {finishedDelivery.displayableStopNumber}</h2>
        </div>
        <div
          className='flex-column'
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}>
          {anomaliesForStop.length > 0 && (
            <>
              <StopHeader>Hinweise</StopHeader>
              <ul className={Styles.WarningList}>
                {anomaliesForStop.map((a, i) => (
                  <li key={i} className={Styles.Item}>
                    <Warning>
                      <div className={Styles.ItemContent}>
                        {AnomalyReason[a.name]}
                        <span className={Styles.Count}>{a.count > 1 && <>{a.count}</>}</span>
                      </div>
                    </Warning>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div
          className='flex-column'
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '15px',
            marginBottom: '30px',
          }}>
          <StopHeader>Stopp {finishedDelivery.displayableStopNumber}</StopHeader>
          <div className='customer-values-container' style={{ fontWeight: 500 }}>
            <div className={classNames('value-actual-hour')}>{`${finishedDelivery.finishedAt?.format(
              'HH:mm'
            )} Uhr`}</div>
            <div className='values-actual-container'>
              <div className='value-actual'>
                <MdPerson style={{ fontSize: '18px' }} />
                <span style={{ marginLeft: '3px' }}>{customersNumber || '0'}</span>
              </div>
              <div className='value-actual'>
                <img src={arrowDown} alt='' />
                <span style={{ marginLeft: '3px' }}>{deliverCount || '0'}</span>
              </div>
              <div className='value-actual'>
                <img src={arrowUp} alt='' />
                <span style={{ marginLeft: '3px' }}>{collectCount || '0'}</span>
              </div>
            </div>
          </div>
          <div className='address-signature-container'>
            {addressComponent}
            <div className='signature-stop-detail'>
              <SignatureImageContainer finishedDelivery={finishedDelivery} url={url} />
            </div>
          </div>
        </div>

        <div
          className='flex-column'
          style={{
            flexGrow: 1,
            width: 'auto',
            height: '200px',
            marginBottom: '30px',
          }}>
          <StopHeader>Position</StopHeader>
          {markerDetails.length ? (
            <>{map}</>
          ) : (
            <div className={Styles.NoMap}>
              <span>Für den ausgewählten Stopp sind keine Geo-Daten vorhanden</span>
            </div>
          )}
          {markerDetails
            .filter((marker: MarkerDetails) => marker.markerRef === clickedMarkerRef)
            .map(
              (marker: MarkerDetails, i) =>
                marker.isClustered &&
                markerClicked &&
                finishedDelivery.deliveryMode !== 'paketShop' && (
                  <div key={`markerModal${i}${marker.stopNumber}`} className='package-list-modal-container'>
                    <StopListToolTip
                      finishedDelivery={finishedDelivery}
                      customers={customers}
                      selectedCustomerLat={selectedCustomerLat}
                      deliveryReturnType={'deliveryItems'}
                    />
                    <StopListToolTip
                      finishedDelivery={finishedDelivery}
                      customers={customers}
                      selectedCustomerLat={selectedCustomerLat}
                      deliveryReturnType={'returnDeliveryItems'}
                    />
                  </div>
                )
            )}
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <div className='flex-column item-list-container' style={{ marginTop: 20 }}>
          <StopHeader offset={15}>Sendungen</StopHeader>
          <DeliveryItemsList
            finishedDelivery={finishedDelivery}
            shipmentIdToCustomerMap={shipmentIdToCustomerMap}
            customers={customers}
            anomalies={anomalies}
          />
        </div>
      </div>
    </div>
  );
};

export default FinishedDeliveryStopDetail;
