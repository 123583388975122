import ValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/ValueExpression';
import svGutschriften from './schaden-verlust-gutschriften';
import svRegresse from './schaden-verlust-regresse';
import anfahrtszeit from './anfahrtszeit';
import rueckfahrtzeit from './rueckfahrtzeit';
import stoppsDauer from './stopps-dauer';
import anfahrtsstrecke from './anfahrtsstrecke';
import rueckfahrtstrecke from './rueckfahrtstrecke';
import stoppsStrecke from './stopps-strecke';
import standardleistungenTour from './standardleistungen-tour';
import bulkyTour from './bulky-tour';
import identServiceTour from './ident-service-tour';
import nachnahmeTour from './nachnahme-tour';
import gepaeckAbholungTour from './gepaeck-abholung-tour';
import zeitfensterTour from './zeitfenster-tour';
import zusMitnahmeTour from './zus-mitnahme-tour';
import quittungslosTour from './quittungslos-tour';
import coincidentTour from './coincident-tour';
import psStoppsTour from './ps-stopps-tour';
import psSendungenTour from './ps-sendungen-tour';
import atgStoppsTour from './atg-stopps-tour';
import atgSendungenTour from './atg-sendungen-tour';
import eilSendungenTour from './eil-sendungen-tour';
import sendungenZustellart from './sendungen-zustellart';
import sendungenZugestelltUhrzeit from './sendungen-zugestellt-uhrzeit';
import sendungenZugestelltEmpaenger from './sendungen-zugestellt-empaenger';
import sendungenDauervollmacht from './sendungen-dauervollmacht';
import sendungenEilservice from './sendungen-eilservice';
import sendungenIdentservice from './sendungen-identservice';
import sendungenNachnahme from './sendungen-nachnahme';
import sendungenWunschzeitfenster from './sendungen-wunschzeitfenster';
import sendungenQuittungslos from './sendungen-quittungslos';
import nachnahmeBetrag from './nachnahme-betrag';
import empfaenger from './empfaenger';
import abholvorgaenge from './abholvorgaenge';
import abholvorgaengeUhrzeit from './abholvorgaenge-uhrzeit';
import abholvorgaengeSendugsanzahl from './abholvorgaenge-sendugsanzahl';
import abholvorgaengeQuelle from './abholvorgaenge-quelle';
import sendungenAbgeholt from './sendungen-abgeholt';
import sendungenAbgeholtSendungsart from './sendungen-abgeholt-sendungsart';
import sendungenAbgeholtUhrzeit from './sendungen-abgeholt-uhrzeit';
import zustellvorgaenge from './zustellvorgaenge';
import zustellvorgaengeEmpfaengeranzahl from './zustellvorgaenge-empfaengeranzahl';
import zustellvorgaengeSendungsanzahl from './zustellvorgaenge-sendungsanzahl';
import geldbetrag from './geldbetrag';
import benachrichtigungen from './benachrichtigungen';
import benachrichtigungenArt from './benachrichtigungen-art';
import wertsendungenTour from './wertsendungen-tour';
import stoppsanzahlNachweisart from './stoppsanzahl-nachweisart';
import touren from './touren';
import tourenV2 from './touren-v2';
import werktage from './werktage';
import nettoZustellzeitAlt from './netto-zustellzeit-alt';
import gpsGenauigkeit from './gps-genauigkeit';
import gpsVerzoegerung from './gps-verzoegerung';
import prognoseSendungseingangsmenge from './prognose-sendungseingangsmenge';
import prognoseSendungseingangsmengeDatum from './prognose-sendungseingangsmenge-datum';
import tourenByLastTourdate from './touren-by-last-tourdate';
import tourverguetungSum from './tourverguetung-sum';
import tourverguetungLeistungSum from './tourverguetung-leistung-sum';
import tourverguetungAbrechnungsartSum from './tourverguetung-abrechnungsart-sum';
import einwohner from './einwohner';
import flaeche from './flaeche';
import servicebeanstandungenZustelltour from './servicebeanstandungen-zustelltour';
import servicebeanstandungenMeldung from './servicebeanstandungen-meldung';
import servicebeanstandungenMeldungGrund from './servicebeanstandungen-meldung-grund';
import liegenbleiber from './liegenbleiber';
import liegenbleiberEil from './liegenbleiber-eil';
import lagernde from './lagernd';
import afnus from './afnus';
import bestand from './bestand';
import bestandEil from './bestand-eil';
import zugangsmenge from './zugangsmenge';
import anzahlTag from './anzahl-tag';
import anzahlTagEPlus0 from './anzahl-tag-e-plus-0';
import anzahlTagExpress from './anzahl-tag-express';
import anzahlTagExpressEPlus0 from './anzahl-tag-express-e-plus-0';
import anzahlTagZsbPlus0 from './anzahl-tag-zsb-plus-0';
import anzahlTagZsbPlus0Successful from './anzahl-tag-zsb-plus-0-successful';
import anzahlTagZsbPlus0Express from './anzahl-tag-zsb-plus-0-express';
import anzahlTagZsbPlus0ExpressSuccessful from './anzahl-tag-zsb-plus-0-express-successful';
import beanstandungenQuote from './beanstandungen-quote';
import beanstandungenQuoteVerlustrelevant from './beanstandungen-quote-verlustrelevant';
import abwicklungsmengeZsb from './abwicklungsmenge-zsb';
import abwicklungsmenge from './abwicklungsmenge';
import abholungenByReturnType from './abholungen-by-return-type';
import mitnahmenType from './mitnahmen-by-return-type';
import abholungen from './abholungen';
import abholungenMitnahmen from './abholungen-mitnahmen';
import mitnahmen from './mitnahmen';
import atgAbholungen from './atg-abholungen';
import psAbgaben from './ps-abgaben';
import psMitnahmenByReturnType from './ps-mitnahmen-by-return-type';
import psMitnahmen from './ps-mitnahmen';
import ruecklaufAbholkartenGrund from './ruecklauf-abholkarten-grund';
import ruecklaufAbholauftraege from './ruecklauf-abholauftraege';
import ruecklauferEilGrund from './ruecklaufer-eil-grund';
import ruecklauferEilGruende from './ruecklaufer-eil-gruende';
import ruecklaufer from './ruecklaufer';
import ruecklauferEil from './ruecklaufer-eil';
import abholauftraegeInclNichtGestartet from './abholauftraege-incl-nicht-gestartet';
import sollLademenge from './lademenge-soll';
import sollLademengeAvg from './lademenge-soll-avg';
import istLademenge from './lademenge-ist';
import istLademengeAvg from './lademenge-ist-avg';
import abholauftraege from './abholauftraege';
import abholauftraegeSuccessful from './abholauftraege-successful';
import zustellungenArt from './zustellungen-art';
import zustellungen from './zustellungen';
import wertsendungenTourSbInclNichtGestartet from './wertsendungen-tour-sb-incl-nicht-gestartet';
import bulkyTourSb from './bulky-tour-sb';
import bulkyTourSbZugestellt from './bulky-tour-sb-zugestellt';
import lademengeSendungsart from './lademenge-sendungsart';
import hinweisHtWzfZustellung from './hinweis-ht-wzf-zustellung';
import hinweisHtWzfAbholung from './hinweis-ht-wzf-abholung';
import hinweisAtgWzfAbholung from './hinweis-atg-wzf-abholung';
import hinweisWzfUnknown from './hinweis-wzf-unknown';
import hinweisTagversetzteBearbeitung from './hinweis-tagversetzte-bearbeitung';
import hinweisRuecklaufAmWunschtag from './hinweis-ruecklauf-am-wunschtag';
import hinweisAusserhalbZustellzeit from './hinweis-ausserhalb-zustellzeit';
import hinweisTeillieferungMehrcollig from './hinweis-teillieferung-mehrcollig';
import hinweisRuecklaufAmTourende from './hinweis-ruecklauf-am-tourende';
import bruttoZustellzeit from './brutto-zustellzeit';
import nettoHtZeit from './netto-ht-zeit';
import nettoPsZeit from './netto-ps-zeit';
import nettoAtgZeit from './netto-atg-zeit';
import ruecklaufGruende from './ruecklauf-gruende';
import dauervollmachtquote from './dauervollmachtquote';
import ruecklauferAbholauftrag from './ruecklaufer-abholauftrag';
import lademengeSendungsarten from './lademenge-sendungsarten';
import zustellungenArten from './zustellungen-arten';
import zustellungenAvg from './zustellungen-avg';
import hinweisAusserhalbWzf from './hinweis-ausserhalb-wzf';
import servicebeanstandungenQuoteZsb from './servicebeanstandungen-quote-zsb';
import servicebeanstandungenQuoteVerlustrelevant from './servicebeanstandungen-quote-verlustrelevant';
import ruecklauferAvg from './ruecklaufer-avg';
import ruecklaufQuote from './ruecklauf-quote';
import nettoZustellzeitAltAvg from './netto-zustellzeit-alt-avg';
import bruttoZustellzeitAvg from './brutto-zustellzeit-avg';
import nettoHtZeitAvg from './netto-ht-zeit-avg';
import nettoPsZeitAvg from './netto-ps-zeit-avg';
import nettoAtgZeitAvg from './netto-atg-zeit-avg';
import nettoZustellzeit from './netto-zustellzeit';
import nettoZustellzeitAvg from './netto-zustellzeit-avg';
import htProduktivitaet from './ht-produktivitaet';
import psProduktivitaet from './ps-produktivitaet';
import atgProduktivitaet from './atg-produktivitaet';
import abholungenAvg from './abholungen-avg';
import atgAbholungenAvg from './atg-abholungen-avg';
import schadenVerlustQuote from './schaden-verlust-quote';
import produktivitaetAlt from './produktivitaet-alt';
import servicebeanstandungenMeldungVerlustrelevant from './servicebeanstandungen-meldung-verlustrelevant';
import servicebeanstandungenQuote from './servicebeanstandungen-quote';
import servicebeanstandungenAvg from './servicebeanstandungen-avg';
import servicebeanstandungenGruende from './servicebeanstandungen-gruende';
import psMitnahmenAvg from './ps-mitnahmen-avg';
import psAbgabenAvg from './ps-abgaben-avg';
import anfahrtszeitAvg from './anfahrtszeit-avg';
import rueckfahrtzeitAvg from './rueckfahrtzeit-avg';
import tourfahrzeit from './tourfahrzeit';
import tourfahrzeitAvg from './tourfahrzeit-avg';
import anfahrtsstreckeAvg from './anfahrtsstrecke-avg';
import anfahrtsstreckeAvgV2 from './anfahrtsstrecke-avg-v2';
import rueckfahrtstreckeAvg from './rueckfahrtstrecke-avg';
import tourstrecke from './tourstrecke';
import tourstreckeAvg from './tourstrecke-avg';
import fortschritt from './tourfortschritt';
import liegenbleiberQuote from './liegenbleiber-quote';
import liegenbleiberEilQuote from './liegenbleiber-eil-quote';
import lagerndQuote from './lagernd-quote';
import ePlus0Quote from './e-plus-0-quote';
import ePlus0EilQuote from './e-plus-0-eil-quote';
import ePlus0ZsbQuote from './e-plus-0-zsb-quote';
import ePlus0ZsbEilQuote from './e-plus-0-zsb-eil-quote';
import benachrichtigungenQuote from './benachrichtigungen-quote';
import benachrichtigungenTypes from './benachrichtigungen-types';
import fotoUnterschriftenNutzungsquote from './foto-unterschriften-nutzungsquote';
import lademengePerKm from './lademenge-per-km';
import lademengePerEinwohner from './lademenge-per-einwohner';
import einwohnerPerKm from './einwohner-per-km';
import zustellungenPerEinwohner from './zustellungen-per-einwohner';
import zustellungenPerHour from './zustellungen-per-hour';
import zustellungenPerKm from './zustellungen-per-km';
import psMitnahmenReturnTypes from './ps-mitnahmen-return-types';
import mitnahmenReturnTypes from './mitnahmen-return-types';
import abholungenReturnTypes from './abholungen-return-types';
import zustellungenNachbarQuote from './zustellungen-nachbar-quote';
import tourFreigabeZeitpunkt from './tour-freigabe-zeitpunkt';
import altgeraetemitnahme from './altgeraete-mitnahme';
import tourStoppsHt from './tour-stops-ht';
import tourStoppsPs from './tour-stops-ps';
import tourStoppsAtg from './tour-stops-atg';
import reihenfolgeEinhaltungQuote from './reihenfolgeeinhaltung-quote';
import sendungenTanTour from './sendungen-tan-tour';
import sendungenTanZugestellt from './sendungen-tan-zustellungen';
import sendungenTanRuecklauf from './sendungen-tan-ruecklaeufer';

export default new Map<string, ValueExpression>([
  [svGutschriften.identifier, svGutschriften],
  [svRegresse.identifier, svRegresse],
  [anfahrtszeit.identifier, anfahrtszeit],
  [rueckfahrtzeit.identifier, rueckfahrtzeit],
  [stoppsDauer.identifier, stoppsDauer],
  [anfahrtsstrecke.identifier, anfahrtsstrecke],
  [rueckfahrtstrecke.identifier, rueckfahrtstrecke],
  [stoppsStrecke.identifier, stoppsStrecke],
  [standardleistungenTour.identifier, standardleistungenTour],
  [bulkyTour.identifier, bulkyTour],
  [identServiceTour.identifier, identServiceTour],
  [nachnahmeTour.identifier, nachnahmeTour],
  [gepaeckAbholungTour.identifier, gepaeckAbholungTour],
  [zeitfensterTour.identifier, zeitfensterTour],
  [zusMitnahmeTour.identifier, zusMitnahmeTour],
  [quittungslosTour.identifier, quittungslosTour],
  [coincidentTour.identifier, coincidentTour],
  [psStoppsTour.identifier, psStoppsTour],
  [psSendungenTour.identifier, psSendungenTour],
  [atgStoppsTour.identifier, atgStoppsTour],
  [atgSendungenTour.identifier, atgSendungenTour],
  [eilSendungenTour.identifier, eilSendungenTour],
  [sendungenZustellart.identifier, sendungenZustellart],
  [sendungenZugestelltUhrzeit.identifier, sendungenZugestelltUhrzeit],
  [sendungenZugestelltEmpaenger.identifier, sendungenZugestelltEmpaenger],
  [sendungenDauervollmacht.identifier, sendungenDauervollmacht],
  [sendungenEilservice.identifier, sendungenEilservice],
  [sendungenIdentservice.identifier, sendungenIdentservice],
  [sendungenNachnahme.identifier, sendungenNachnahme],
  [sendungenWunschzeitfenster.identifier, sendungenWunschzeitfenster],
  [sendungenQuittungslos.identifier, sendungenQuittungslos],
  [nachnahmeBetrag.identifier, nachnahmeBetrag],
  [empfaenger.identifier, empfaenger],
  [abholvorgaenge.identifier, abholvorgaenge],
  [abholvorgaengeUhrzeit.identifier, abholvorgaengeUhrzeit],
  [abholvorgaengeSendugsanzahl.identifier, abholvorgaengeSendugsanzahl],
  [abholvorgaengeQuelle.identifier, abholvorgaengeQuelle],
  [sendungenAbgeholt.identifier, sendungenAbgeholt],
  [sendungenAbgeholtSendungsart.identifier, sendungenAbgeholtSendungsart],
  [sendungenAbgeholtUhrzeit.identifier, sendungenAbgeholtUhrzeit],
  [zustellvorgaenge.identifier, zustellvorgaenge],
  [zustellvorgaengeEmpfaengeranzahl.identifier, zustellvorgaengeEmpfaengeranzahl],
  [zustellvorgaengeSendungsanzahl.identifier, zustellvorgaengeSendungsanzahl],
  [geldbetrag.identifier, geldbetrag],
  [benachrichtigungen.identifier, benachrichtigungen],
  [benachrichtigungenArt.identifier, benachrichtigungenArt],
  [wertsendungenTour.identifier, wertsendungenTour],
  [stoppsanzahlNachweisart.identifier, stoppsanzahlNachweisart],
  [touren.identifier, touren],
  [tourenV2.identifier, tourenV2],
  [werktage.identifier, werktage],
  [nettoZustellzeitAlt.identifier, nettoZustellzeitAlt],
  [gpsGenauigkeit.identifier, gpsGenauigkeit],
  [gpsVerzoegerung.identifier, gpsVerzoegerung],
  [prognoseSendungseingangsmenge.identifier, prognoseSendungseingangsmenge],
  [prognoseSendungseingangsmengeDatum.identifier, prognoseSendungseingangsmengeDatum],
  [tourenByLastTourdate.identifier, tourenByLastTourdate],
  [tourverguetungSum.identifier, tourverguetungSum],
  [tourverguetungLeistungSum.identifier, tourverguetungLeistungSum],
  [tourverguetungAbrechnungsartSum.identifier, tourverguetungAbrechnungsartSum],
  [einwohner.identifier, einwohner],
  [flaeche.identifier, flaeche],
  [servicebeanstandungenZustelltour.identifier, servicebeanstandungenZustelltour],
  [servicebeanstandungenMeldung.identifier, servicebeanstandungenMeldung],
  [servicebeanstandungenMeldungGrund.identifier, servicebeanstandungenMeldungGrund],
  [liegenbleiber.identifier, liegenbleiber],
  [liegenbleiberEil.identifier, liegenbleiberEil],
  [lagernde.identifier, lagernde],
  [afnus.identifier, afnus],
  [bestand.identifier, bestand],
  [bestandEil.identifier, bestandEil],
  [zugangsmenge.identifier, zugangsmenge],
  [anzahlTag.identifier, anzahlTag],
  [anzahlTagEPlus0.identifier, anzahlTagEPlus0],
  [anzahlTagExpress.identifier, anzahlTagExpress],
  [anzahlTagExpressEPlus0.identifier, anzahlTagExpressEPlus0],
  [anzahlTagZsbPlus0.identifier, anzahlTagZsbPlus0],
  [anzahlTagZsbPlus0Successful.identifier, anzahlTagZsbPlus0Successful],
  [anzahlTagZsbPlus0Express.identifier, anzahlTagZsbPlus0Express],
  [anzahlTagZsbPlus0ExpressSuccessful.identifier, anzahlTagZsbPlus0ExpressSuccessful],
  [beanstandungenQuote.identifier, beanstandungenQuote],
  [beanstandungenQuoteVerlustrelevant.identifier, beanstandungenQuoteVerlustrelevant],
  [abwicklungsmengeZsb.identifier, abwicklungsmengeZsb],
  [abwicklungsmenge.identifier, abwicklungsmenge],
  [abholungenByReturnType.identifier, abholungenByReturnType],
  [mitnahmenType.identifier, mitnahmenType],
  [abholungenMitnahmen.identifier, abholungenMitnahmen],
  [abholungen.identifier, abholungen],
  [mitnahmen.identifier, mitnahmen],
  [atgAbholungen.identifier, atgAbholungen],
  [psAbgaben.identifier, psAbgaben],
  [psMitnahmenByReturnType.identifier, psMitnahmenByReturnType],
  [psMitnahmen.identifier, psMitnahmen],
  [ruecklaufAbholkartenGrund.identifier, ruecklaufAbholkartenGrund],
  [ruecklaufAbholauftraege.identifier, ruecklaufAbholauftraege],
  [ruecklauferEilGrund.identifier, ruecklauferEilGrund],
  [ruecklaufer.identifier, ruecklaufer],
  [ruecklauferEil.identifier, ruecklauferEil],
  [abholauftraegeInclNichtGestartet.identifier, abholauftraegeInclNichtGestartet],
  [istLademenge.identifier, istLademenge],
  [istLademengeAvg.identifier, istLademengeAvg],
  [sollLademenge.identifier, sollLademenge],
  [sollLademengeAvg.identifier, sollLademengeAvg],
  [abholauftraege.identifier, abholauftraege],
  [abholauftraegeSuccessful.identifier, abholauftraegeSuccessful],
  [zustellungenArt.identifier, zustellungenArt],
  [zustellungen.identifier, zustellungen],
  [zustellungenNachbarQuote.identifier, zustellungenNachbarQuote],
  [wertsendungenTourSbInclNichtGestartet.identifier, wertsendungenTourSbInclNichtGestartet],
  [bulkyTourSb.identifier, bulkyTourSb],
  [bulkyTourSbZugestellt.identifier, bulkyTourSbZugestellt],
  [lademengeSendungsart.identifier, lademengeSendungsart],
  [hinweisHtWzfZustellung.identifier, hinweisHtWzfZustellung],
  [hinweisHtWzfAbholung.identifier, hinweisHtWzfAbholung],
  [hinweisAtgWzfAbholung.identifier, hinweisAtgWzfAbholung],
  [hinweisWzfUnknown.identifier, hinweisWzfUnknown],
  [hinweisTagversetzteBearbeitung.identifier, hinweisTagversetzteBearbeitung],
  [hinweisRuecklaufAmWunschtag.identifier, hinweisRuecklaufAmWunschtag],
  [hinweisAusserhalbZustellzeit.identifier, hinweisAusserhalbZustellzeit],
  [hinweisTeillieferungMehrcollig.identifier, hinweisTeillieferungMehrcollig],
  [hinweisRuecklaufAmTourende.identifier, hinweisRuecklaufAmTourende],
  [bruttoZustellzeit.identifier, bruttoZustellzeit],
  [nettoHtZeit.identifier, nettoHtZeit],
  [nettoPsZeit.identifier, nettoPsZeit],
  [nettoAtgZeit.identifier, nettoAtgZeit],
  [dauervollmachtquote.identifier, dauervollmachtquote],
  [zustellungenAvg.identifier, zustellungenAvg],
  [hinweisAusserhalbWzf.identifier, hinweisAusserhalbWzf],
  [servicebeanstandungenQuoteZsb.identifier, servicebeanstandungenQuoteZsb],
  [servicebeanstandungenQuoteVerlustrelevant.identifier, servicebeanstandungenQuoteVerlustrelevant],
  [ruecklauferAvg.identifier, ruecklauferAvg],
  [ruecklaufQuote.identifier, ruecklaufQuote],
  [nettoZustellzeitAltAvg.identifier, nettoZustellzeitAltAvg],
  [bruttoZustellzeitAvg.identifier, bruttoZustellzeitAvg],
  [nettoHtZeitAvg.identifier, nettoHtZeitAvg],
  [nettoPsZeitAvg.identifier, nettoPsZeitAvg],
  [nettoAtgZeitAvg.identifier, nettoAtgZeitAvg],
  [nettoZustellzeit.identifier, nettoZustellzeit],
  [nettoZustellzeitAvg.identifier, nettoZustellzeitAvg],
  [htProduktivitaet.identifier, htProduktivitaet],
  [psProduktivitaet.identifier, psProduktivitaet],
  [atgProduktivitaet.identifier, atgProduktivitaet],
  [abholungenAvg.identifier, abholungenAvg],
  [atgAbholungenAvg.identifier, atgAbholungenAvg],
  [schadenVerlustQuote.identifier, schadenVerlustQuote],
  [produktivitaetAlt.identifier, produktivitaetAlt],
  [servicebeanstandungenMeldungVerlustrelevant.identifier, servicebeanstandungenMeldungVerlustrelevant],
  [servicebeanstandungenQuote.identifier, servicebeanstandungenQuote],
  [servicebeanstandungenAvg.identifier, servicebeanstandungenAvg],
  [psMitnahmenAvg.identifier, psMitnahmenAvg],
  [psAbgabenAvg.identifier, psAbgabenAvg],
  [anfahrtszeitAvg.identifier, anfahrtszeitAvg],
  [rueckfahrtzeitAvg.identifier, rueckfahrtzeitAvg],
  [tourfahrzeit.identifier, tourfahrzeit],
  [tourfahrzeitAvg.identifier, tourfahrzeitAvg],
  [anfahrtsstreckeAvg.identifier, anfahrtsstreckeAvg],
  [anfahrtsstreckeAvgV2.identifier, anfahrtsstreckeAvgV2],
  [rueckfahrtstreckeAvg.identifier, rueckfahrtstreckeAvg],
  [tourstrecke.identifier, tourstrecke],
  [tourstreckeAvg.identifier, tourstreckeAvg],
  [fortschritt.identifier, fortschritt],
  [liegenbleiberQuote.identifier, liegenbleiberQuote],
  [liegenbleiberEilQuote.identifier, liegenbleiberEilQuote],
  [lagerndQuote.identifier, lagerndQuote],
  [ePlus0Quote.identifier, ePlus0Quote],
  [ePlus0EilQuote.identifier, ePlus0EilQuote],
  [ePlus0ZsbQuote.identifier, ePlus0ZsbQuote],
  [ePlus0ZsbEilQuote.identifier, ePlus0ZsbEilQuote],
  [benachrichtigungenQuote.identifier, benachrichtigungenQuote],
  [fotoUnterschriftenNutzungsquote.identifier, fotoUnterschriftenNutzungsquote],
  [lademengePerKm.identifier, lademengePerKm],
  [lademengePerEinwohner.identifier, lademengePerEinwohner],
  [einwohnerPerKm.identifier, einwohnerPerKm],
  [zustellungenPerEinwohner.identifier, zustellungenPerEinwohner],
  [zustellungenPerHour.identifier, zustellungenPerHour],
  [zustellungenPerKm.identifier, zustellungenPerKm],
  [tourFreigabeZeitpunkt.identifier, tourFreigabeZeitpunkt],
  [altgeraetemitnahme.identifier, altgeraetemitnahme],
  [tourStoppsHt.identifier, tourStoppsHt],
  [tourStoppsPs.identifier, tourStoppsPs],
  [tourStoppsAtg.identifier, tourStoppsAtg],
  [reihenfolgeEinhaltungQuote.identifier, reihenfolgeEinhaltungQuote],
  [sendungenTanTour.identifier, sendungenTanTour],
  [sendungenTanZugestellt.identifier, sendungenTanZugestellt],
  [sendungenTanRuecklauf.identifier, sendungenTanRuecklauf],
  ...ruecklaufGruende.map(
    (ruecklaufGrund: ValueExpression) => [ruecklaufGrund.identifier, ruecklaufGrund] as [string, ValueExpression]
  ),
  ...ruecklauferAbholauftrag.map(
    (ruecklauferAbholauftrag: ValueExpression) =>
      [ruecklauferAbholauftrag.identifier, ruecklauferAbholauftrag] as [string, ValueExpression]
  ),
  ...ruecklauferEilGruende.map(
    (ruecklauferEilGrund: ValueExpression) =>
      [ruecklauferEilGrund.identifier, ruecklauferEilGrund] as [string, ValueExpression]
  ),
  ...lademengeSendungsarten.map(
    (lademengeSendungsart: ValueExpression) =>
      [lademengeSendungsart.identifier, lademengeSendungsart] as [string, ValueExpression]
  ),
  ...zustellungenArten.map(
    (zustellungenArt: ValueExpression) => [zustellungenArt.identifier, zustellungenArt] as [string, ValueExpression]
  ),
  ...servicebeanstandungenGruende.map(
    (servicebeanstandungenGrund: ValueExpression) =>
      [servicebeanstandungenGrund.identifier, servicebeanstandungenGrund] as [string, ValueExpression]
  ),
  ...benachrichtigungenTypes.map(
    (benachrichtigungenType: ValueExpression) =>
      [benachrichtigungenType.identifier, benachrichtigungenType] as [string, ValueExpression]
  ),
  ...psMitnahmenReturnTypes.map(
    (psMitnahmenReturnType: ValueExpression) =>
      [psMitnahmenReturnType.identifier, psMitnahmenReturnType] as [string, ValueExpression]
  ),
  ...mitnahmenReturnTypes.map(
    (mitnahmenReturnType: ValueExpression) =>
      [mitnahmenReturnType.identifier, mitnahmenReturnType] as [string, ValueExpression]
  ),
  ...abholungenReturnTypes.map(
    (abholungenReturnType: ValueExpression) =>
      [abholungenReturnType.identifier, abholungenReturnType] as [string, ValueExpression]
  ),
]);
