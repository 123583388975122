import React, { createContext, PropsWithChildren, ReactElement, useContext, useState } from 'react';
import sateliteImgSrc from '../../../../../assets/images/map_style_sattelite.png';
import flattImgSrc from '../../../../../assets/images/map_style_flat.png';
import { ConfigKey, getEnvVariable } from '../../../../../modules/common/services/EnvironmentConfigurationService';

type MapStyleNames = 'default' | 'stop' | 'stopSatellite' | 'dark' | 'satellite';
type MapConfig = {
  mapStyleURL: string;
  toggleImgSrc: string;
};

// export for unit testing
export const mapStyleConfig: Record<MapStyleNames, MapConfig> = {
  default: {
    mapStyleURL: getEnvVariable(ConfigKey.MAPBOX_STYLE_DEFAULT),
    toggleImgSrc: sateliteImgSrc,
  },
  satellite: {
    mapStyleURL: getEnvVariable(ConfigKey.MAPBOX_STYLE_SATELLITE),
    toggleImgSrc: flattImgSrc,
  },
  dark: {
    mapStyleURL: getEnvVariable(ConfigKey.MAPBOX_STYLE_DARK),
    toggleImgSrc: flattImgSrc,
  },
  stop: {
    mapStyleURL: getEnvVariable(ConfigKey.MAPBOX_STYLE_STOPP),
    toggleImgSrc: sateliteImgSrc,
  },
  stopSatellite: {
    mapStyleURL: getEnvVariable(ConfigKey.MAPBOX_STYLE_STOPPSAT),
    toggleImgSrc: flattImgSrc,
  },
};

export type MapStyleContextType = {
  mapConfig: MapConfig;
  toggleMapStyle: () => void;
};

const defaultValue: MapStyleContextType = null;
export const MapStyleContext = createContext(defaultValue);

export const useMapStyleContext = (): MapStyleContextType => {
  return useContext<MapStyleContextType>(MapStyleContext);
};

export type MapStyleContextProviderProps = Required<
  PropsWithChildren<{
    primaryStyle?: MapStyleNames;
    secondaryStyle?: MapStyleNames;
  }>
>;

export function MapStyleContextProvider({
  children,
  primaryStyle = 'default',
  secondaryStyle = 'satellite',
}: MapStyleContextProviderProps): ReactElement {
  const [mapStyleName, setMapStyleName] = useState<MapStyleNames>(primaryStyle);

  const toggleMapStyle = () => {
    if (mapStyleName === primaryStyle) {
      setMapStyleName(secondaryStyle);
    } else {
      setMapStyleName(primaryStyle);
    }
  };

  return (
    <MapStyleContext.Provider
      value={{
        mapConfig: mapStyleConfig[mapStyleName],
        toggleMapStyle,
      }}>
      {children}
    </MapStyleContext.Provider>
  );
}

export default MapStyleContextProvider;
