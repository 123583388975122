import { HinweisData, TourIdentifier } from '@data-table/data-table.types';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { IRawAnomalyResponse } from '@legacy-modules/dashboard/anomalies/interfaces/IRawAnomalyResponse';
import { AnomalyReason } from '@legacy-modules/dashboard/anomalies/utils/AnomalyUtils';
import { DateFormat } from '@legacy-modules/dashboard/components/table/DateTimeCell';
import { Duration } from '@legacy-modules/dashboard/models/enums/Duration';
import { knownAnomalyFilter } from '@legacy-modules/tour/utils/KnownAnomalyFilter';
import { selectTokenId } from '@redux/auth.selectors';
import { DurationUtils } from '@utils/duration-utils';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const baseUrl = getEnvVariable(ConfigKey.HTTP_API_URL);
const queryFn = (authToken: string, orgKey: string, from: string, to: string): Promise<IRawAnomalyResponse[]> => {
  const url = new URL(`${baseUrl}/anomalies/find`);
  url.searchParams.set('token', authToken);
  url.searchParams.append('org-key', orgKey);
  url.searchParams.append('from', from);
  url.searchParams.append('to', to);
  return fetch(url).then((res) => res.json());
};

const useHinweiseData = (orgKey: string, duration: Duration, enabled: boolean): [HinweisData[], boolean] => {
  const authToken = useSelector(selectTokenId);

  const dataRange = DurationUtils.getDateRange(duration);

  const { from, to } = {
    from: dataRange.from.format('YYYYMMDD'),
    to: dataRange.to.format('YYYYMMDD'),
  };

  const { data, isLoading } = useQuery<IRawAnomalyResponse[], unknown, HinweisData[]>({
    queryKey: ['anomalies', 'find', { orgKey, from, to }],
    queryFn: () => queryFn(authToken, orgKey, from, to),
    enabled: !!orgKey && !!duration && enabled,
    select: (data) => {
      const groups = data?.filter(knownAnomalyFilter)?.reduce((acc, anomaly) => {
        const hinweisart = AnomalyReason[anomaly.anomalyType];
        const tourdatum = moment(anomaly?.tourIdentifier?.tourDate).format(DateFormat.Date);
        const group = `${tourdatum}|${anomaly.tourIdentifier.tourNumber}|${hinweisart}`;
        if (acc.has(group)) {
          acc.get(group).push({
            ...anomaly,
            details: undefined,
            tourIdentifier: {
              tourNumber: anomaly?.tourIdentifier?.tourNumber?.toString(),
              orgKey: null,
              orgId: anomaly?.tourIdentifier?.zsbOrgId?.toString(),
              tourDate: anomaly?.tourIdentifier?.tourDate,
              contractorKey: anomaly?.contractorKey,
            } as TourIdentifier,
            hinweisart,
            tourdatum,
          });
        } else {
          acc.set(group, [
            {
              ...anomaly,
              details: undefined,
              tourIdentifier: {
                tourNumber: anomaly?.tourIdentifier?.tourNumber?.toString(),
                orgKey: null,
                orgId: anomaly?.tourIdentifier?.zsbOrgId?.toString(),
                tourDate: anomaly?.tourIdentifier?.tourDate,
                contractorKey: anomaly?.contractorKey,
              } as TourIdentifier,
              hinweisart,
              tourdatum,
            },
          ]);
        }
        return acc;
      }, new Map<string, HinweisData[]>());
      return Array.from(groups.values()).map((entries) => {
        const entry = entries[0];
        if (entry.anomalyLevel === 'tour') {
          return {
            ...entry,
            details: `${entries?.length} Tourenbereich`,
          };
        } else if (['wzf.ht-zustellung', 'wzf.ht-abholung', 'wzf.atg-abholung'].includes(entry.anomalyType)) {
          return {
            ...entry,
            details: `${entries.length} Zeitfenster`,
          };
        } else {
          const suffix = entries.length === 1 ? 'Sendung' : 'Sendungen';
          return {
            ...entry,
            details: `${entries.length} ${suffix}`,
          };
        }
      });
    },
  });

  return [data || [], isLoading];
};

export default useHinweiseData;
