import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FilterButton } from './FilterButton';
import { DeliveryBadge, buildFilterBadges } from '../utils/getDeliveryBadge/GetDeliveryBadge';
import { BadgeSortFn } from '../utils/BadgeSortOrder';
import { useTourDetailsContext } from '@other-components/tour-details-context';

import '../assets/filterBadgePanel.scss';

type SingleFilterBadgeItemProps = {
  selectedCount: number;
  badge: DeliveryBadge;
  selectedBadges: string[];
  handleClickBadge: (badge: DeliveryBadge) => void;
};

const SingleFilterBadgeItem = ({
  handleClickBadge,
  selectedBadges,
  badge,
  selectedCount,
}: SingleFilterBadgeItemProps) => {
  const isActive = useMemo(() => {
    if (badge == null) return false;
    return selectedBadges.some((o) => o === badge.value);
  }, [badge, selectedBadges]);

  const isDisabled = useMemo(() => {
    if (badge == null) return false;
    if (selectedCount === 0) {
      return !selectedBadges.some((o) => o === badge.value);
    }
  }, [badge, selectedBadges, selectedCount]);

  return (
    <FilterButton
      active={isActive}
      count={badge.packagesAmount}
      disabled={isDisabled}
      onSelect={() => handleClickBadge(badge)}
      iconSrc={null}
      tooltipText={
        <>
          {badge.description} <br />
          Stopps: {selectedCount} <br />
          Sendungen: {badge.packagesAmount}
        </>
      }
      customIcon={
        <span
          className='badge-span-container'
          style={{
            display: 'inline-block',
            color: 'white',
            backgroundColor: badge.color,
            borderRadius: '2px',
            padding: '0 2px',
            textAlign: 'center',
            width: '30px',
            fontSize: '13px',
            pointerEvents: 'none',
          }}>
          {badge.value}
        </span>
      }
    />
  );
};

const FilterBadgePanel: React.FC = () => {
  const {
    finishedDeliveries: stopList,
    zustellartFilterState: [selectedBadges, setZustellartFilterState],
  } = useTourDetailsContext();

  const _handleClickBadge = useCallback(
    (badge: DeliveryBadge) => {
      setZustellartFilterState((state) => {
        if (state.includes(badge.value)) {
          return state.filter((o) => o !== badge.value);
        } else {
          return [...state, badge.value];
        }
      });
    },
    [setZustellartFilterState]
  );

  const [badges, setBadges] = useState<DeliveryBadge[]>([]);

  useEffect(() => {
    setBadges(buildFilterBadges(stopList));
  }, [stopList]);

  const sortedBadges = useMemo(() => [...badges].sort(BadgeSortFn), [badges]);

  return (
    <div className={'filter-wrapper-main'}>
      {sortedBadges.map((badge, i) => (
        <SingleFilterBadgeItem
          key={`${badge.value}${i}`}
          handleClickBadge={_handleClickBadge}
          selectedBadges={selectedBadges}
          badge={badge}
          selectedCount={badge.stopsAmount}
        />
      ))}
    </div>
  );
};

export default FilterBadgePanel;
