import { ShipmentSearchEntryApiModel } from '../models/entities/ShipmentSearchEntry';
import Logger from '../../utils/logging/Logger';
import { NetworkError } from '../../tour/errors/NetworkError';
import { ConfigKey, getEnvVariable } from '../../common/services/EnvironmentConfigurationService';

const signatureUrl = getEnvVariable(ConfigKey.HTTP_API_URL);

export default class ShipmentSearchDataProvider {
  _logger: Logger = Logger.getInstance('ShipmentSearchDataProvider');

  url(barcode: string, token: string): string {
    return signatureUrl + '/shipments/' + barcode + '?token=' + token;
  }

  async query(barcode: string, tokenId: string): Promise<ShipmentSearchEntryApiModel[] | null | undefined> {
    if (barcode == null || barcode.length === 0) return [];
    return fetch(this.url(barcode, tokenId)).then(this._onResponse.bind(this), this._onError.bind(this));
  }

  _onError(error) {
    throw new NetworkError('Query failed');
  }

  _onResponse(res) {
    if (res && Math.floor(res.status / 100) === 2) {
      return res.json();
    }
    if (res && res.status === 404) {
      return [];
    } else {
      throw new NetworkError('Query failed');
    }
  }
}
