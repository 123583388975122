/* eslint-disable max-len */
import FinishedDelivery, { PutDownLocation } from '../../models/entities/FinishedDelivery';
import DeliveryModes from '../../models/enumerations/DeliveryMode';
import {
  UndeliverableReasonShortLabels,
  UndeliverableReasonBadgeAbbreviation,
} from '../../../metrics2/models/enumerations/UndeliverableReason';
import CustomerTrackingData from '../../models/types/CustomerTrackingData';
import ReturnTypes from '../../models/enumerations/ReturnType';
import { recipientPersonMapping } from './GetDeliveryBadgeMappings';
import { countBy, uniq } from 'lodash';
import { badgeDictionary } from '@components/delivery-badge/types';

type BadgeType = 'normal' | 'mixed' | 'grouped';

export type DeliveryBadge = {
  value: string;
  description: string;
  type: BadgeType;
  packagesAmount?: number;
  stopsAmount?: number;
  color?: string;
  index?: number;
  grouping?: Record<string, number>;
};

export interface Rule {
  shouldApply: (finishedDelivery: FinishedDelivery, customer?: CustomerTrackingData) => boolean;
  getValue: (finishedDelivery: FinishedDelivery, customer?: CustomerTrackingData) => DeliveryBadge;
  getFilterValue?: (finishedDelivery: FinishedDelivery) => DeliveryBadge[];
}

const fallbackRule: Rule = {
  shouldApply: () => true,
  getValue: (finishedDelivery: FinishedDelivery) => ({
    value: 'UNB',
    description: finishedDelivery.deliveryMode,
    color: null,
    type: 'normal',
  }),
};

const getRecipientPersons = (customer: CustomerTrackingData, finishedDelivery: FinishedDelivery): string[] => {
  if (customer && finishedDelivery.customerDeliveries[customer.customerRef]) {
    return [finishedDelivery.customerDeliveries[customer.customerRef].recipientPerson];
  } else if (Object.keys(finishedDelivery.customerDeliveries).length > 0) {
    return Object.values(finishedDelivery.customerDeliveries)
      .map((d) => d.recipientPerson)
      .sort();
  } else {
    return [];
  }
};

const noFinishedDelivery: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => finishedDelivery == null,
  getValue: () => badgeDictionary.default,
};

const personDeliveryReturn: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery, customer?: CustomerTrackingData) => {
    const recipientPerson = getRecipientPersons(customer, finishedDelivery)[0];
    return (
      finishedDelivery.deliveryMode === DeliveryModes.person &&
      finishedDelivery.returnDeliveryItems.length > 0 &&
      !recipientPerson
    );
  },
  getValue: (finishedDelivery: FinishedDelivery) => {
    for (const deliveryItem of finishedDelivery.returnDeliveryItems) {
      switch (deliveryItem.returnParcelDetails.returnType) {
        case ReturnTypes.DBCourier:
          return badgeDictionary.DB;
        case ReturnTypes.NewParcel:
          if (deliveryItem.returnParcelDetails.clientType === 'ProPS') {
            return badgeDictionary.PRO;
          } else {
            return badgeDictionary.PRI;
          }
        case ReturnTypes.ReturnToSender:
          return badgeDictionary.RET;
        case ReturnTypes.Undeliverable:
          return badgeDictionary.RL;
      }
    }
    return badgeDictionary.SEZ;
  },
};

export const personDeliveryRule: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => {
    if (finishedDelivery.deliveryMode === DeliveryModes.person) return true;
  },
  getValue: (finishedDelivery: FinishedDelivery, customer?: CustomerTrackingData) => {
    const recipientPerson = getRecipientPersons(customer, finishedDelivery)[0];

    const mappedValue = recipientPersonMapping[recipientPerson];
    if (mappedValue) {
      return mappedValue;
    }
    return badgeDictionary.SEZ;
  },
};

const personDeliveryPlus: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery, customer?: CustomerTrackingData) => {
    const recipientPerson = getRecipientPersons(customer, finishedDelivery)[0];
    if (finishedDelivery.deliveryMode !== DeliveryModes.person) return false;
    if (customer || Object.keys(finishedDelivery.customerDeliveries).length === 0) {
      return false;
    }
    if (!recipientPersonMapping[recipientPerson]) {
      return false;
    }
    const mappedValue = recipientPersonMapping[recipientPerson];
    const distinctModes = new Set(Object.values(finishedDelivery.customerDeliveries).map((cd) => cd.recipientPerson));
    return distinctModes.size > 1 && mappedValue.value.indexOf('+') < 0;
  },
  getValue: (finishedDelivery: FinishedDelivery) => {
    const recipientPersons = getRecipientPersons(null, finishedDelivery);
    const hasDifferentRecipientPersons = new Set(recipientPersons).size > 1;
    const grouping = hasDifferentRecipientPersons
      ? countBy(recipientPersons?.map((recipientPerson) => recipientPersonMapping[recipientPerson]?.value))
      : undefined;
    if (recipientPersons.includes('intendedReceiver') && hasDifferentRecipientPersons) {
      return {
        value: `${recipientPersonMapping[recipientPersons[0]]?.value}+`, // FIXME: first recipient person still used to define combined value
        description: 'Originalempfänger mit Nachbarschaftsabgabe',
        color: '#13DA1C',
        type: hasDifferentRecipientPersons ? 'grouped' : 'normal',
        grouping,
      };
    }

    return badgeDictionary.SEZ;
  },
  getFilterValue: (finishedDelivery: FinishedDelivery) => {
    const recipientPersons = getRecipientPersons(null, finishedDelivery);
    const hasDifferentRecipientPersons = new Set(recipientPersons).size > 1;
    const grouping = hasDifferentRecipientPersons
      ? countBy(recipientPersons?.map((recipientPerson) => recipientPersonMapping[recipientPerson]?.value))
      : undefined;
    return recipientPersons?.map((recipientPerson) => {
      const mappedValue = recipientPersonMapping[recipientPerson];
      if (mappedValue) {
        return {
          ...mappedValue,
          type: hasDifferentRecipientPersons ? 'grouped' : 'normal',
          grouping,
        };
      }
      return badgeDictionary.SEZ;
    });
  },
};

const undelivered: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => finishedDelivery.deliveryMode === DeliveryModes.undeliverable,
  getValue: (finishedDelivery: FinishedDelivery) => {
    const reasonLabel = UndeliverableReasonShortLabels[finishedDelivery.undeliverableReason];
    const reasonShortLabel = UndeliverableReasonBadgeAbbreviation[finishedDelivery.undeliverableReason];
    if (reasonShortLabel) {
      return {
        color: 'red',
        value: reasonShortLabel,
        description: reasonLabel,
        type: 'normal',
      };
    } else {
      return {
        ...badgeDictionary.RL,
        description: 'Rücklauf',
        color: 'red',
      };
    }
  },
};

const paketShop: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => finishedDelivery.deliveryMode === DeliveryModes.paketShop,
  getValue: () => {
    return badgeDictionary.PS;
  },
};

const props: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => finishedDelivery.deliveryMode === DeliveryModes.props,
  getValue: () => {
    return badgeDictionary.PRO;
  },
};

const onTimeAuth: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) =>
    finishedDelivery.deliveryMode === DeliveryModes.oneTimeAuthorizationPutDown,
  getValue: () => {
    return badgeDictionary.EV;
  },
};

export const putDownOutside: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => finishedDelivery.deliveryMode === DeliveryModes.putDownOutside,
  getValue: (finishedDelivery: FinishedDelivery) => {
    const mapping = putDownLocationMappingType[finishedDelivery.putDownLocation];
    if (mapping) {
      return mapping;
    }
    // the default is "DV" for backward compatibility reasons
    return badgeDictionary.DV;
  },
};

// From 7th June on, deliveryItems have the status itself too, before only the parent "FinishedDelivery" had it
const isNewApi = (fd: FinishedDelivery) =>
  fd.deliveryItems.every((d) => d.status != null) && fd.returnDeliveryItems.every((d) => d.status != null);

const mixedReturnAndDelivery: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => {
    if (!isNewApi(finishedDelivery)) return false;
    const { deliveryItems } = finishedDelivery;

    return (
      deliveryItems.length > 0 &&
      deliveryItems.some((d) => d.status.startsWith('RL')) &&
      deliveryItems.some((d) => !d.status.startsWith('RL'))
    );
  },
  getValue: (finishedDelivery: FinishedDelivery) => {
    const all = [...finishedDelivery.returnDeliveryItems, ...finishedDelivery.deliveryItems].map((s) => s.status);
    const grouped = countBy(all);

    // TODO: Verify this is correct!
    return {
      value: '',
      description: '',
      type: 'mixed',
      grouping: grouped,
    };
  },
};

const differentReturnStops: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => {
    if (!isNewApi(finishedDelivery)) return false;
    const { returnDeliveryItems } = finishedDelivery;
    if (!returnDeliveryItems.every((d) => !d.status.startsWith('RL N'))) {
      return false;
    }
    const uniqDeliveriesStatus = uniq(returnDeliveryItems.map((d) => d.status));
    return uniqDeliveriesStatus.length > 1;
  },
  getValue: (finishedDelivery: FinishedDelivery) => {
    const all = [...finishedDelivery.returnDeliveryItems, ...finishedDelivery.deliveryItems].map((s) => s.status);
    const grouped = countBy(all);

    return {
      ...badgeDictionary.RL,
      description: 'Rücklauf',
      color: 'red',
      type: 'grouped',
      grouping: grouped,
    };
  },
};

const differentReturnStopsWithN: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => {
    if (!isNewApi(finishedDelivery)) return false;
    const { deliveryItems } = finishedDelivery;
    return deliveryItems.some((d) => d.status.startsWith('RL N'));
  },
  getValue: (finishedDelivery: FinishedDelivery) => {
    const { deliveryItems } = finishedDelivery;
    const uniqDeliveriesStatus = uniq(deliveryItems.map((d) => d.status));
    if (uniqDeliveriesStatus.length === 1 && uniqDeliveriesStatus[0].startsWith('RL N')) {
      const orderOfN = uniqDeliveriesStatus[0].split(' ')[1];
      return badgeDictionary[orderOfN];
    }

    const all = [...finishedDelivery.returnDeliveryItems, ...finishedDelivery.deliveryItems].map((s) => s.status);
    const grouped = countBy(all);

    return {
      ...badgeDictionary.N,
      grouping: grouped,
    };
  },
  getFilterValue: (finishedDelivery: FinishedDelivery) => {
    const { deliveryItems } = finishedDelivery;
    return deliveryItems?.map((dItem) => {
      const orderOfN = dItem?.status?.split(' ')?.[1];
      return badgeDictionary[orderOfN];
    });
  },
};

const differentReturnStopsWithTan: Rule = {
  shouldApply: (finishedDelivery: FinishedDelivery) => {
    if (!isNewApi(finishedDelivery)) return false;
    const { deliveryItems } = finishedDelivery;
    return deliveryItems.some((d) => d.status.startsWith('RL T'));
  },
  getValue: (finishedDelivery: FinishedDelivery) => {
    const { deliveryItems } = finishedDelivery;
    const uniqDeliveriesStatus = uniq(deliveryItems.map((d) => d.status));
    if (uniqDeliveriesStatus.length === 1 && uniqDeliveriesStatus[0].startsWith('RL T')) {
      const orderOfN = uniqDeliveriesStatus[0].split(' ')[1];
      return badgeDictionary[orderOfN];
    }

    const all = [...finishedDelivery.returnDeliveryItems, ...finishedDelivery.deliveryItems].map((s) => s.status);
    const grouped = countBy(all);

    return {
      ...badgeDictionary.N,
      grouping: grouped,
    };
  },
  getFilterValue: (finishedDelivery: FinishedDelivery) => {
    const { deliveryItems } = finishedDelivery;
    return deliveryItems?.map((dItem) => {
      const orderOfN = dItem?.status?.split(' ')?.[1];
      return badgeDictionary[orderOfN];
    });
  },
};

const ruleSet: Rule[] = [
  noFinishedDelivery,
  paketShop,
  mixedReturnAndDelivery,
  differentReturnStopsWithN,
  differentReturnStops,
  differentReturnStopsWithTan,
  personDeliveryReturn,
  personDeliveryPlus,
  personDeliveryRule,
  undelivered,
  props,
  onTimeAuth,
  putDownOutside,
  // has to be at the end
  fallbackRule,
];

type PutDownLocationMappingType = {
  [k in PutDownLocation]: DeliveryBadge;
};

const putDownLocationMappingType: PutDownLocationMappingType = {
  [PutDownLocation.Mailbox]: badgeDictionary.BK,
  [PutDownLocation.Bag]: badgeDictionary.TU,
  [PutDownLocation.PermanentAuth]: badgeDictionary.DV,
  [PutDownLocation.OneTimeAuth]: badgeDictionary.EV,
  [PutDownLocation.BnkAuthorization]: badgeDictionary.BV,
  [PutDownLocation.UnknownAuthorization]: badgeDictionary.VM,
};

const buildDeliveryBadge = (finishedDelivery: FinishedDelivery, customer?: CustomerTrackingData): DeliveryBadge => {
  const packagesAmount = finishedDelivery
    ? finishedDelivery.deliveryItems.length + finishedDelivery.returnDeliveryItems.length
    : undefined;

  for (const r of ruleSet) {
    if (r.shouldApply(finishedDelivery, customer)) {
      return {
        ...r.getValue(finishedDelivery, customer),
        packagesAmount,
      };
    }
  }
};

export const filterStopsByBadge = (badges: Array<string>, list: FinishedDelivery[]) => {
  // if there are no filters, we can return the list unfiltered
  if (!badges.length) return list;

  return list.filter((finishedDelivery) => {
    const deliveryBadge = buildDeliveryBadge(finishedDelivery);
    if (
      deliveryBadge.value === 'N' && // an tour badge containing grouped Sendungen with status "RL N1", "RL N2", "RL N3", "RL N4"
      !!deliveryBadge['grouping'] && // has object containing grouped Sendungen with status "RL N1", "RL N2", "RL N3", "RL N4"
      badges.some((badge) => badge === 'N1' || badge === 'N2' || badge === 'N3' || badge === 'N4') // filter if entries are any of "N1", "N2", "N3", "N4"
    ) {
      // show or hide Stops containing Sendungen with "N(x)" status
      // based on tour badge grouping object
      return badges.some((badge) => deliveryBadge['grouping'][`RL ${badge}`] > 0);
    } else if (deliveryBadge.value === 'OE+' && !!deliveryBadge['grouping']) {
      return badges.some((badge) => deliveryBadge['grouping'][badge] > 0);
    } else {
      return badges.includes(deliveryBadge?.value);
    }
  });
};

export const buildFilterBadges = (stops: FinishedDelivery[]): DeliveryBadge[] => {
  return (
    stops
      // run each stop over each rule to produce filter badges list
      ?.map((stop) => {
        for (const rule of ruleSet) {
          if (rule.shouldApply(stop)) {
            if (!rule?.getFilterValue) {
              return [
                {
                  ...rule?.getValue(stop),
                  packagesAmount: stop.deliveryItems.length + stop.returnDeliveryItems.length,
                },
              ];
            }
            return rule?.getFilterValue(stop);
          }
        }
      })
      ?.flat()
      // combine similar badges together by value and count packages amount
      ?.reduce((prev: DeliveryBadge[], current: DeliveryBadge) => {
        const existingBadgeIndex = prev.findIndex((existingBadge) => existingBadge.value === current.value);
        if (existingBadgeIndex === -1) {
          prev.push({
            ...current,
            packagesAmount: current.packagesAmount || 1,
          });
        } else {
          const newPackagesAmount = (prev[existingBadgeIndex]?.packagesAmount || 0) + (current?.packagesAmount || 1);
          prev[existingBadgeIndex] = {
            ...prev[existingBadgeIndex],
            ...current,
            packagesAmount: newPackagesAmount,
          };
        }
        return prev;
      }, [])
      // set stops amount for each list entry
      .map((deliveryBadge: DeliveryBadge) => {
        return {
          ...deliveryBadge,
          stopsAmount: filterStopsByBadge([deliveryBadge?.value], stops)?.length,
        };
      })
  );
};

export default buildDeliveryBadge;
