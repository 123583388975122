export enum DeliveryStatus {
  AUS_ATG = 'AUS ATG',
  EL_Tour = 'EL Tour',
  TA_mAvis = 'TA mAvis',
  TA_oAvis = 'TA oAvis',
  Storno_TA = 'Storno TA',
  Abgabe_PS = 'Abgabe PS',
  RL_A = 'RL A',
  RL_F = 'RL F',
  RL_N1 = 'RL N1',
  RL_N2 = 'RL N2',
  RL_N3 = 'RL N3',
  RL_N4 = 'RL N4',
  RL_T1 = 'RL T1',
  RL_T2 = 'RL T2',
  RL_T3 = 'RL T3',
  RL_T4 = 'RL T4',
  RL_U = 'RL U',
  RL_S = 'RL S',
  RL_Z = 'RL Z',
  RL_KG = 'RL KG',
  RL_IL = 'RL IL',
  RL_IN = 'RL IN',
  RL_IG = 'RL IG',
  RL_ID = 'RL ID',
  RL_IA = 'RL IA',
  RL_oGGS = 'RL oGGS',
  RL_Storno = 'RL Storno',
  Ret_Abh = 'Ret Abh',
  Ret_Mit = 'Ret Mit',
  SEZ_Medea = 'SEZ Medea',
  SEZ_BK = 'SEZ BK',
}
