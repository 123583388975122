import { OrgKey, OrgName } from '@data-table/data-table.types';
import { ConfigKey, getEnvVariable } from '@legacy-modules/common/services/EnvironmentConfigurationService';
import { selectTokenId } from '@redux/auth.selectors';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const baseUrl = getEnvVariable(ConfigKey.HTTP_API_URL);
const queryFn = (authToken: string, orgKeys: string[]) => {
  const url = new URL(`${baseUrl}/orgunits/names`);
  url.searchParams.set('token', authToken);
  orgKeys.forEach((key) => url.searchParams.append('ids', key));
  return fetch(url).then((res) => res.json());
};

const useStandort = (orgKeys: OrgKey[], enabled: boolean = true): Map<OrgKey, OrgName> => {
  const authToken = useSelector(selectTokenId);
  const { data } = useQuery<StandortResponse, unknown, StandortData>({
    queryKey: ['orgunits', 'names', { orgKeys }],
    queryFn: () => queryFn(authToken, orgKeys),
    enabled: orgKeys?.length > 0 && enabled,
    select: (data) => {
      return new Map<OrgKey, OrgName>(Object.entries(data) || []);
    },
    placeholderData: {},
  });
  return data;
};

export default useStandort;

type StandortResponse = {
  [key: OrgName]: OrgName;
};
type StandortData = Map<OrgKey, OrgName>;
