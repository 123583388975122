import { MetricTypeKey } from './metric-type-keys';
import MetricType, { undeliverableReasonsShipmentBased } from '@legacy-modules/metrics2/models/entities/MetricType';
import { PositiveDirection } from '@legacy-modules/metrics2/models/entities/PositiveDirection';
import { AggregationType } from '@legacy-modules/metrics2/models/enumerations/AggregationType';
import {
  UndeliverableReasonLabels,
  UndeliverableReasonShortLabels,
} from '@legacy-modules/metrics2/models/enumerations/UndeliverableReason';
import { ValueType } from '@legacy-modules/metrics2/models/enumerations/ValueType';
import MapDetailValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/MapDetailValueExpression';
import SingleValueExpression from '@legacy-modules/valueexpressions/models/valueexpressions/SingleValueExpression';

const ruecklaufGruendeMetric = new MetricType({
  key: MetricTypeKey.RLbyUndeliverableReason,
  label: 'Rückläufer nach Grund',
  sumLabel: 'Rückläufer',
  category: 'delivery-items',
  description:
    // eslint-disable-next-line max-len
    '<p>Anzahl der erfolgreich bearbeiteten Sendungen, denen ein Rücklaufgrund zugewiesen wurde, nach Grund des Rücklaufs</p>\n',
  aggregation: AggregationType.sum,
  positiveDirection: PositiveDirection.down,
  valueType: ValueType.map,
});

const ruecklaufNachGrund = new SingleValueExpression(ruecklaufGruendeMetric);

const ruecklaufGruende = undeliverableReasonsShipmentBased.flatMap((reason) => {
  const expression = new MapDetailValueExpression(
    ruecklaufGruendeMetric,
    reason,
    UndeliverableReasonLabels[reason] || `unbekannt (${reason})`
  );
  expression.shortLabel = UndeliverableReasonShortLabels[reason];
  expression.setIdentifier(`${MetricTypeKey.RLbyUndeliverableReason}.${reason}`);
  return [expression, ...(expression.getChildren() || [])];
});

const ruecklauferGruende = undeliverableReasonsShipmentBased.map((key) => {
  const m1Value = new MapDetailValueExpression(ruecklaufGruendeMetric, key);
  m1Value.label = UndeliverableReasonLabels[key] || `unbekannt (${key})`;
  m1Value.shortLabel = UndeliverableReasonShortLabels[key];
  m1Value.setIdentifier(`ruecklauefer_gruende.${key}`);
  return m1Value;
});

export default [ruecklaufNachGrund, ...ruecklauferGruende, ...ruecklaufGruende];
