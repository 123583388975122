import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { Tooltip } from '../../common/components/Tooltip';
import Styles from './FinishedDeliveryBadge.module.scss';
import classNames from 'classnames';
import { DeliveryStatus } from '../constants/DeliveryStatus';
import { mapItemStatus } from '../utils/mapItemStatus';
import FinishedDelivery from '../models/entities/FinishedDelivery';
import CustomerTrackingData from '../models/types/CustomerTrackingData';

type Props = {
  status: DeliveryStatus;
  finishedDelivery: FinishedDelivery;
  customer?: CustomerTrackingData;
};

type BadgeProps = {
  shortName: string;
  longName?: string;
  color: string;
  children?: React.ReactChild | React.ReactChild[];
  isMixedBadge?: boolean;
};

export const Badge: React.FC<BadgeProps> = ({ color, longName, shortName, children, isMixedBadge = false }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [hovering, setHovering] = useState(false);
  const showTooltip = useCallback(() => setHovering(true), []);
  const hideTooltip = useCallback(() => setHovering(false), []);

  return (
    <>
      <span
        className={classNames('badge-span-container', Styles.Badge, {
          [Styles.mixed]: isMixedBadge,
        })}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
        ref={ref}
        style={{
          backgroundColor: color,
        }}>
        {shortName}
      </span>
      <Tooltip
        visible={hovering}
        placement={children || isMixedBadge ? 'right' : 'bottom'}
        anchorElement={ref?.current}>
        {!children && longName}
        {children}
      </Tooltip>
    </>
  );
};

const DeliveryBadge: React.FC<Props> = (props) => {
  const { status, finishedDelivery, customer } = props;
  const mapped = mapItemStatus(status, finishedDelivery, customer);
  
  return (
    <Badge
      // FIXME: temporary quick-fix solution. mapped object type should be equal
      shortName={mapped.value || mapped.badgeAbbreviation}
      // FIXME: temporary quick-fix solution. mapped object type should be equal
      longName={mapped.description || mapped.label}
      color={mapped.color}
    />
  );
};

export default DeliveryBadge;
